import Vue from 'vue';
import Vuex from 'vuex';
import { cloneDeep, isEmpty, isNil } from 'lodash/lang';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    languages: ['de', 'en', 'el', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'ru'], // , 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'ru', 'cs', 'el', 'fi', 'hu', 'ja', 'ro', 'sk', 'sl', 'sv', 'zh'],
    language: null,
    hotel: null,
    hotelId: null,
    confirmationNumber: null,
    pinCode: null,
    serviceBus: null,
    regionSettings: null,
    ws: null,
    kioskPair: null,
    kioskPaired: false,
    kioskMeldeblatt: null,
    reservationGroup: null,
  },
  mutations: {
    'kiosk.pair'(state, pair) {
      let hotelId = state.hotelId;
      if (!isNil(pair) && !isNil(pair.hotelId)) hotelId = pair.hotelId;

      if (pair.action !== 'kiosk.pair') {
        pair = null;
      }

      try {
        if (isNil(pair)) localStorage.removeItem(`${hotelId}_kioskPair`);
        else localStorage.setItem(`${hotelId}_kioskPair`, JSON.stringify(pair));
      } catch (e) { /* ignore */ }
      try {
        if (isNil(pair)) Cookies.remove(`${hotelId}_kioskPair`);
        else Cookies.set(`${hotelId}_kioskPair`, JSON.stringify(pair), { expires: 10 * 365 });
      } catch (e) { /* ignore */ }

      state.kioskPair = pair;
    },
    'kiosk.meldeblatt'(state, kioskMeldeblatt) {
      state.kioskMeldeblatt = kioskMeldeblatt;
    },
    'kiosk.paired'(state, paired) {
      state.kioskPaired = paired;
    },
    serviceBus(state, serviceBus) {
      state.serviceBus = serviceBus;
    },
    regionSettings(state, regionSettings) {
      state.regionSettings = regionSettings;
    },
    language(state, language) {
      state.language = language;
    },
    hotelId(state, hotelId) {
      state.hotelId = hotelId;
    },
    ws(state, ws) {
      if (isNil(ws) || state.ws !== ws) {
        state.kioskPaired = false;
      }
      state.ws = ws;
    },
    'portal.reservationGroup'(state, reservationGroup) {
      state.reservationGroup = reservationGroup;
    },
    'portal.confirmationNumber'(state, confirmationNumber) {
      if (state.confirmationNumber !== confirmationNumber) {
        state.reservationGroup = null;
      }
      state.confirmationNumber = confirmationNumber;
    },
    'portal.pinCode'(state, pinCode) {
      if (state.pinCode !== pinCode) {
        state.reservationGroup = null;
      }
      state.pinCode = pinCode;
    },
    hotel(state, hotel) {
      if (!isEmpty(state.hotelId) && !isEmpty(hotel)) {
        try {
          localStorage.setItem('lastHotelId', state.hotelId);
        } catch (_) { /* ignore */ }
      }
      const tmp = cloneDeep(hotel);

      try {
        if (!isEmpty(tmp)) {
          const fillMissingTranslations = (type, field) => {
            if (!isEmpty(tmp[type])) {
              tmp[type].forEach((it) => {
                state.languages.forEach((lang) => {
                  if (!isEmpty(it[field]) && isEmpty(it[field][lang])) it[field][lang] = it[field][tmp.details.fallbackLanguage] || it[field][tmp.details.primaryLanguage] || '';
                });
              });
            }
          };

          fillMissingTranslations('roomTypes', 'name');
          fillMissingTranslations('roomTypes', 'description');
          fillMissingTranslations('ratePlans', 'name');
          fillMissingTranslations('ratePlans', 'description');
          fillMissingTranslations('ratePlans', 'longDescription');
          fillMissingTranslations('inHouseServices', 'name');
          fillMissingTranslations('inHouseServices', 'description');
          fillMissingTranslations('ageCategories', 'name');
        }
      } catch (ignore) { /* ignore */ }
      state.hotel = tmp;

      try {
        const whiteLabel = state.hotel.hotel.whiteLabel;
        if (!isEmpty(whiteLabel)) {
          document.getElementById('script__output').innerHTML = '';
          document.querySelectorAll(`script[type="text/plain"][data-whitelabel="${whiteLabel}"]`).forEach((it) => {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            if (!isNil(it.getAttribute('src'))) {
              s.src = it.getAttribute('src');
              s.innerHTML = null;
            } else {
              s.innerHTML = it.innerHTML;
            }
            document.getElementById('script__output').appendChild(s);
          });
        }
      } catch (e) { /* ignore */ }
    },
  },
  getters: {
    hotel: (state) => state.hotel,
    hotelId: (state) => state.hotelId,
    language: (state) => state.language,
    languages: (state) => state.languages,
    regionSettings: (state) => state.regionSettings,
    serviceBus: (state) => state.serviceBus,
    ws: (state) => state.ws,
    'portal.reservationGroup': (state) => state.reservationGroup,
    'portal.confirmationNumber': (state) => state.confirmationNumber,
    'portal.pinCode': (state) => state.pinCode,
    'kiosk.meldeblatt': (state) => state.kioskMeldeblatt,
    'kiosk.paired': (state) => state.kioskPaired,
    'kiosk.pair': (state) => (hotelId) => {
      if (isNil(state.kioskPair)) {
        try {
          const tmp = localStorage.getItem(`${hotelId}_kioskPair`);
          if (!isEmpty(tmp)) {
            state.kioskPair = JSON.parse(tmp);
          }
        } catch (e) { /* ignore */ }
        try {
          const tmp = Cookies.get(`${hotelId}_kioskPair`);
          if (!isEmpty(tmp)) {
            state.kioskPair = JSON.parse(tmp);
          }
        } catch (e) { /* ignore */ }
      }

      return state.kioskPair;
    },
  },
  actions: {
  },
  modules: {
  },
});
