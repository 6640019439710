<template>
  <div :class="['bg-grayVeryLight min-h-screen relative w-full mx-auto lg:mx-0 p-24 pb-96 lg:px-64 lg:py-128 xl:border-r border-grayLight max-w-700 col-start-1 lg:col-start-2 row-start-2 lg:row-start-1 lg:row-span-2', { 'max-w-1400': wide }, styleClass]">
    <button v-if="backButton" class="back-link hidden lg:grid grid-cols-2 text-primary font-semibold absolute top-80" @click="$serviceBus.$emit('back')">
      <chevron-left-icon size="1.5x" class="" /><span>{{ $t('back') }}</span>
    </button>
    <slot />
  </div>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons';

export default {
  name: 'Column1',

  components: {
    ChevronLeftIcon,
  },

  props: {
    styleClass: {
      type: String,
      default: '',
    },
    wide: {
      type: Boolean,
      default: false,
      required: false,
    },
    backButton: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data: function () {
    return {
    };
  },

  computed: {
  },
  methods: {
  },

  i18n: {
    messages: {
      de: {
        back: 'Zurück',
      },
      en: {
        back: 'Back',
      },
      es: {
        back: 'Volver',
      },
      fr: {
        back: 'Dos',
      },
      it: {
        back: 'Indietro',
      },
      nl: {
        back: 'Terug',
      },
      pl: {
        back: 'Powrót',
      },
      pt: {
        back: 'Voltar',
      },
      ru: {
        back: 'Назад',
      },
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

.back-link {
  grid-template-columns: min-content auto;
  grid-column-gap: 1rem;
}

.legal-links {
  @apply grid;
  grid-template-columns: min-content 1rem min-content;
  white-space: nowrap;

  @media (max-width: 1279px) {
    span {
      display: none;
    }
    margin-top: -10px;
    grid-template-columns: min-content;
    grid-auto-rows: 1fr;
  }
  @media (min-width: 1280px) {

  }
}

</style>
