import { isNil, isEmpty } from 'lodash/lang';
import Cookies from 'js-cookie';

export default {

  computed: {
    languages: function () {
      return this.$store.getters.languages || ['de', 'en'];
    },
  },

  mounted() {
    let languagePreference = null;
    try {
      languagePreference = Cookies.get('languagePreference');
    } catch (ex) { /* ignore */ }

    if (isEmpty(languagePreference)) {
      languagePreference = window.languagePreference;
    }

    let browserLanguage = navigator.language || navigator.userLanguage;
    if (!isEmpty(browserLanguage)) {
      browserLanguage = browserLanguage.substring(0, 2);
    }

    // console.log(`preference: ${languagePreference}`);
    // console.log(`browser: ${browserLanguage}`);
    // console.log(`current: ${this.$i18n.locale}`);

    if (isEmpty(languagePreference)) {
      if (browserLanguage !== this.$i18n.locale) {
        this.$serviceBus.$emit('languageModal.show');
        window.languagePreference = this.$i18n.locale;
      } else {
        // do nothing, browser language matches currently selected language
      }
    } else {
      this.$i18n.local = languagePreference;
    }

    this.$serviceBus.$on('languageModal.confirm', (newLocale) => {
      try {
        window.languagePreference = newLocale;
        Cookies.set('languagePreference', newLocale, { expires: 7 });
      } catch (e) { /* ignore */ }
    });
  },

  beforeDestroy() {
    this.$serviceBus.$off('languageModal.confirm');
  },

  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (!isEmpty(oldLocale) && !isEmpty(newLocale)) {
        try {
          window.languagePreference = newLocale;
          Cookies.set('languagePreference', newLocale, { expires: 7 });
        } catch (e) { /* ignore */ }
      }
    },
  },
};
