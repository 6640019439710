<template>
  <component
    :is="InputDateLegacy"
    v-if="legacy"
    v-model="internalValue"
    :name="name"
    :locale="locale"
    :disabled="disabled"
    :label="label"
    :placeholder="placeholder"
    :optional="optional"
    class="class"
    :invalid="invalid"
    :validation="validation"
    :min-date="minDate"
    :max-date="maxDate"
    @blur="$emit('blur')"
  >
  </component>
  <div v-else class="mb-24">
    <label
      class="input-label text-14 text-dark font-medium mb-8 block"
      :class="[ {'invalid': invalid }]"
      :for="name"
    >
      {{ label }} <span class="text-gray">{{ optional ? '(optional)' : '' }}</span>
    </label>
    <!-- :key is required, because placeholder is not interactive and otherwise placeholder stays in there after signature -->
    <input
      :id="name"
      :value="formattedDate"
      type="text"
      :name="name"
      class="input focus:border-primary active:border-primary appearance-none focus:outline-none"
      :class="[ {'invalid': invalid }]"
      :disabled="disabled"
      readonly
      @click="picker = true"
      @focus="onFocus"
      @blur="onBlur"
    />
    <date-picker
      v-if="picker"
      v-model="internalValue"
      :locale="locale"
      :min-date="minDate"
      :max-date="maxDate"
      @input="picker = false"
      @close="picker = false"
    />
    <!--    <flat-pickr-->
    <!--      ref="date"-->
    <!--      :key="`${disabled}`"-->
    <!--      v-model="internalValue"-->
    <!--      :config="flatPickrConfig"-->
    <!--      class="form-control"-->
    <!--      :placeholder="disabled ? '' : placeholder"-->
    <!--      :disabled="disabled"-->
    <!--      name="date"-->
    <!--      @focus="onFocus"-->
    <!--      @blur="onBlur"-->
    <!--    />-->
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import InputMixin from '../../../mixins/InputMixin';
import ErrorMessage from '../ErrorMessage';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
// import 'flatpickr/dist/themes/light.css';
// import { German } from 'flatpickr/dist/l10n/de';
// import { english } from 'flatpickr/dist/l10n/default';
import fecha from 'fecha';
// import yearDropdownPlugin from '../../../plugins/yearDropdownPlugin';
// import dobDatepicker from 'date-of-birth-js/dist/js/date-of-birth-js.min';
import DatePicker from './DatePicker';
import { isNil, isEmpty } from 'lodash/lang';
import InputDateLegacy from './InputDateLegacy.vue';

export default {
  // components: { ErrorMessage, flatPickr },
  components: { DatePicker, ErrorMessage, InputDateLegacy },
  mixins: [InputMixin],
  props: {
    value: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    locale: String,
    minDate: {
      type: String,
      default: '1900-01-01',
    },
    maxDate: {
      type: String,
      default: () => new Date().toISOString().substring(0, 10),
    },
    legacy: {
      tpe: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      picker: false,
      internalValue: null,
      InputDateLegacy: InputDateLegacy,
    };
  },

  computed: {
    // flatPickrConfig() {
    //   return {
    //     altInput: true,
    //     altFormat: this.locale === 'de' ? 'd.m.Y' : 'd-m-Y',
    //     allowInput: false,
    //     dateFormat: 'Ymd',
    //     minDate: this.minDate,
    //     maxDate: this.maxDate,
    //     disableMobile: this.disabled,
    //     altInputClass: `input focus:border-primary active:border-primary appearance-none focus:outline-none ${this.class || ''}`,
    //     formatDate: this.formatDate,
    //     locale: this.flatPickrLocale,
    //     // eslint-disable-next-line new-cap
    //     plugins: [
    //       // eslint-disable-next-line new-cap
    //       new yearDropdownPlugin({
    //         yearStart: 135,
    //         yearEnd: 20,
    //         invalid: this.invalid,
    //       }),
    //     ],
    //   };
    // },
    // flatPickrLocale() {
    //   switch (this.locale.toLowerCase().substring(0, 2)) {
    //     case 'de':
    //       return German;
    //     default:
    //       return english;
    //   }
    // },
    pickerConfig: function () {
      return {
        display_mode: 'popup',
        year_range: 120,
        enable_built_in_validation: false,
        enable_ordinal_number: false,
        show_long_month: true,
        // dateFormat: null,
        zIndex: {
          targetNode: '150',
          datepickerWidget: '150',
          invisibleBackground: '100',
        },
        long_month: this.getMonthNames(this.locale.toLowerCase().substring(0, 2), 'long'),
        short_month: this.getMonthNames(this.locale.toLowerCase().substring(0, 2), 'short'),
        labels: {
          header_label: this.label,
          select_day_label: 'Select day',
          select_month_label: 'Select month',
          select_year_label: 'Select year',
          reset_button_label: 'Reset and try again',
          date_range_label: 'Year ', // label for year section -> "Year 2000 - 2020"
        },
      };
    },
    formattedDate: function () {
      if (isEmpty(this.internalValue)) return '';
      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      return new Intl.DateTimeFormat(this.locale, format).format(fecha.parse(this.internalValue, 'YYYYMMDD'));
    },
  },

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
    },
    invalid: function (newVal) {
      try {
        const date = this.$refs.date;
        if (date && date.fp) {
          const input = date.fp.mobileInput;
          if (input) {
            if (newVal) {
              input.classList.add('invalid');
            } else {
              input.classList.remove('invalid');
            }
          }
        }
      } catch (e) {
        this.$rollbar.error('Failed to set mobile date input validation status', e);
      }
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
  },

  created: function () {
    this.internalValue = this.value;
  },

  mounted: function () {
    this.mounted = true;
    // dobDatepicker(this.$el.querySelector('input[type]'), this.pickerConfig);
  },

  methods: {
    onFocus($event) {
    },
    onBlur($event) {
      this.$emit('blur', $event);
    },
    formatDate(date, requestedFormat, i18n) {
      switch (requestedFormat) {
        case 'Ymd':
          return fecha.format(date, 'YYYYMMDD');
        default:
          break;
      }

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      return new Intl.DateTimeFormat(this.locale, format).format(date);
    },
    getMonthNames(locale = 'en', format = 'long') {
      const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
        const mm = month < 10 ? `0${month}` : month;
        return new Date(`2017-${mm}-01T00:00:00+00:00`);
      });
      return months.map((date) => formatter.format(date));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import '~date-of-birth-js/src/stylesheet/main';

//.flatpickr-current-month .numInputWrapper {
//  display: none;
//}
//.flatpickr-current-month span.cur-month {
//  margin-right: 10px;
//  width: 85px;
//  font-size: 0.75em;
//  top: 0px;
//  padding-top: 10px;
//  padding-right: 2px;
//  position: absolute;
//  left: 0px;
//  font-weight: 600;
//  text-align: right;
//}
//.flatpickr-current-month {
//  width: 100px;
//}
//.flatpickr-year-select {
//  z-index: 100;
//  position: absolute;
//  top: 8px;
//  right: 50px;
//  width: auto;
//
//}
//.flatpickr-year-select select{
//  appearance: menulist;
//  display: block;
//  background: #eceef1;
//  border: none;
//  border-radius: 0;
//  box-sizing: border-box;
//  color: #5a6171;
//  cursor: pointer;
//  font-size: 135%;
//  font-family: inherit;
//  font-weight: 300;
//  height: auto;
//  line-height: inherit;
//  margin: -1px 0 0 0;
//  outline: none;
//  padding: 0 0 0 0.5ch;
//  position: relative;
//  vertical-align: initial;
//  -webkit-box-sizing: border-box;
//  -webkit-appearance: menulist;
//  -moz-appearance: menulist;
//  width: auto;
//}

</style>
