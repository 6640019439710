// define a mixin object
import { isNil } from 'lodash/lang';
import { replace, endsWith, trimEnd } from 'lodash/string';
import fecha from 'fecha';

export default {

  filters: {
    nl2br: function (value) {
      return replace(value, /[\r\n]+/gi, '<br />');
    },
    toCurrency: function (value) {
      let ret = `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`;

      if (endsWith(ret, '00')) {
        ret = trimEnd(ret, '0');
        ret += '-';
      }

      return ret;
    },
    toCurrencyDigits: function (value) {
      return `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`;
    },
    toNumber: function (value) {
      return `${(isNil(value) ? 0 : value).toLocaleString(
        window.i18n.locale,
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        },
      )}`;
    },
  },

  methods: {
    formatDate(date, sourceFormat) {
      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, format).format(fecha.parse(date, sourceFormat));
    },
    formatDateTime(date, sourceFormat) {
      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, format).format(fecha.parse(date, sourceFormat));
    },
  },
};
