<template>
  <div v-if="invalid && !isNil(validation)" class="grid grid-cols-errorMessage mt-8">
    <div>
      <div class="bg-error rounded-8 w-16 h-16 mt-0 ml-2">
        <x-icon class="text-white pt-1 pl-2" size="14"></x-icon>
      </div>
    </div>
    <div v-if="validation.required === false" class="text-error text-12">
      {{ $t('errorMessage.mustNotBeEmpty', [label]) }}
    </div>
    <div v-else-if="validation.minLength === false" class="text-error text-12">
      {{ $t('errorMessage.minLength', [label, validation.$params.minLength.min]) }}
    </div>
    <div v-else-if="validation.checked === false" class="text-error text-12">
      {{ $t('errorMessage.notSelected', [label]) }}
    </div>
    <div v-else-if="validation.accepted === false" class="text-error text-12">
      {{ $t('errorMessage.mustAccept', [label]) }}
    </div>
    <div v-else-if="validation.email === false" class="text-error text-12">
      {{ $t('errorMessage.emailInvalid', [label]) }}
    </div>
    <div v-else-if="validation.custom === false" class="text-error text-12">
      {{ $t(`errorMessage.${validation.$params.custom.message_key || 'invalid'}`, [label]) }}
    </div>
    <div v-else class="text-error text-12">
      {{ $t('errorMessage.invalid', [label]) }} ({{ validation }})
    </div>
  </div>
</template>

<script>

import { XIcon } from 'vue-feather-icons';
import InputMixin from '../../mixins/InputMixin';
import { isNil } from 'lodash/lang';

export default {
  name: 'ErrorMessage',

  components: {
    XIcon,
  },

  mixins: [InputMixin],
  props: {
    label: String,
    validation: Object,
    invalid: Boolean,
  },

  data: function () {
    return {
    };
  },

  computed: {

  },

  methods: {
    isNil(val) {
      return isNil(val);
    },
  },
};
</script>

<style lang="scss">

</style>
