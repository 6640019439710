<template>
  <div class="column2" :class="styleClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Column2',

  props: {
    styleClass: {
      type: String,
      default: 'hidden xl:block mx-auto md:mx-0 p-24 md:px-64 md:pt-128 max-w-700 t-0 ',
    },
  },
};
</script>

<style lang="scss">
  .column2 {
    p {
      @apply mb-16;
    }

    img, iframe {
      @apply border-grayLight border rounded-8 mb-16;

      &.square-corners {
        @apply rounded-0;
      }
    }

    ul {
      @apply mt-16 ml-0;
      list-style-type: '✓';
      list-style-position: outside;
      list-style-image: none;
      list-style: none;

      li {
        @apply pl-20 mb-16;

        &:before {
          @apply text-primary font-semibold inline-block text-16;
          content: '✓';
          width: 2rem;
          margin-left: -2rem;
        }
      }
    }
  }
</style>
