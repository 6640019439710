<template>
  <div>
    <div class="contact">
      <div class="description mb-32 select-none">
        <h1 class="h1 mb-32">
          {{ $t('meldeblatt.headline') }}
        </h1>
        <div class="body text-gray">
          <loading v-if="isNil(meldeblatt)" />
          <template v-else>
            <div class="grid grid-cols-12 gap-24">
              <div class="col-start-1 col-span-12">
                <select v-model="$root.$i18n.locale" type="select" class="language-switcher language-switcher--medium col-span-1">
                  <option v-if="isEmpty(languages) || languages.includes('de')" value="de">
                    Deutsch
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('en')" value="en">
                    English
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('es')" value="es">
                    Español
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('fr')" value="fr">
                    Français
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('it')" value="it">
                    Italiano
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('nl')" value="nl">
                    Nederlands
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('pl')" value="pl">
                    Polski
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('pt')" value="pt">
                    Português
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('ru')" value="ru">
                    Русский язык
                  </option>
                  <option v-if="isEmpty(languages) || languages.includes('el')" value="el">
                    Ελληνικά
                  </option>
                </select>
              </div>

              <p v-if="['AT', 'DE'].includes(hotel.details.countryCode)" class="col-span-12">
                {{ $t(`travelDocumentInfo.${hotel.details.countryCode}`) }}
              </p>
              <h2 class="h2 mb-16 col-span-12">
                {{ $t('meldeblatt.travelData') }}
              </h2>

              <input-text
                :value="formattedDateRange(meldeblatt.anreise, meldeblatt.abreiseGeplant)"
                name="dateRange"
                :label="$t('meldeblatt.guest.dateRange.label')"
                :placeholder="$t('meldeblatt.guest.dateRange.placeholder')"
                :disabled="true"
                class="col-span-12 md:col-span-6"
              ></input-text>
              <input-text
                v-model="meldeblatt.kfzKennzeichen"
                name="kfzKennzeichen"
                :label="$t('meldeblatt.guest.kfzKennzeichen.label')"
                :placeholder="$t('meldeblatt.guest.kfzKennzeichen.placeholder')"
                :disabled="disabled"
                optional
                class="col-span-12 md:col-span-6"
              ></input-text>
              <hr class="border-t border-1 border-grayLight mb-24 block col-span-12 col-start-1" />
              <template v-for="(guest, idx) in meldeblatt.guests">
                <div
                  v-in-viewport="{ margin: '-49% 0px -49% 0px'}"
                  class="grid col-span-12 grid-cols-12 gap-24"
                  :data--guest="idx"
                >
                  <h2 :id="`guest-${idx}`" class="h2 mb-16 col-span-12">
                    {{ $t('meldeblatt.guest.headline') }} {{ idx + 1 }}
                  </h2>
                  <input-text
                    v-model="guest.firstName"
                    name="firstName"
                    :label="$t('meldeblatt.guest.firstName.label')"
                    :placeholder="$t('meldeblatt.guest.firstName.placeholder')"
                    class="col-span-12 md:col-span-6"
                    :disabled="disabled"
                    :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].firstName`)"
                    :validation="$v.meldeblatt.guests.$each[idx].firstName"
                    @blur="touch(`meldeblatt.guests.$each[${idx}].firstName`)"
                  ></input-text>
                  <input-text
                    v-model="guest.lastName"
                    name="lastName"
                    class="col-span-12 md:col-span-6"
                    :disabled="disabled || (idx === 0 && meldeblatt.status === 'Reservation')"
                    :label="$t('meldeblatt.guest.lastName.label')"
                    :placeholder="$t('meldeblatt.guest.lastName.placeholder')"
                    :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].lastName`)"
                    :validation="$v.meldeblatt.guests.$each[idx].lastName"
                    @blur="touch(`meldeblatt.guests.$each[${idx}].lastName`)"
                  ></input-text>
                  <input-select
                    v-model="guest.gender"
                    name="gender"
                    :values="genders"
                    :disabled="disabled"
                    :label="$t('meldeblatt.guest.gender.label')"
                    :placeholder="$t('meldeblatt.guest.gender.placeholder')"
                    :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].gender`)"
                    :validation="$v.meldeblatt.guests.$each[idx].gender"
                    class="col-span-12 md:col-span-6"
                    @blur="touch(`meldeblatt.guests.$each[${idx}].gender`)"
                  >
                  </input-select>
                  <input-text
                    v-model="guest.namePrefix"
                    name="namePrefix"
                    :disabled="disabled"
                    :label="$t('meldeblatt.guest.namePrefix.label')"
                    :placeholder="$t('meldeblatt.guest.namePrefix.placeholder')"
                    optional
                    class="col-span-12 md:col-span-6 xl:col-span-3"
                  ></input-text>
                  <input-text
                    v-model="guest.nameSuffix"
                    name="nameSuffix"
                    :disabled="disabled"
                    :label="$t('meldeblatt.guest.nameSuffix.label')"
                    :placeholder="$t('meldeblatt.guest.nameSuffix.placeholder')"
                    optional
                    class="col-span-12 md:col-span-6 xl:col-span-3"
                  ></input-text>
                  <input-country
                    v-model="guest.nationality"
                    name="nationality"
                    :countries="[hotel.details.countryCode, ...map(meldeblatt.guests || [], 'nationality'), ...map(meldeblatt.guests.address || [], 'country')]"
                    :locale="locale"
                    :disabled="disabled"
                    :label="$t('meldeblatt.guest.nationality.label')"
                    :placeholder="$t('meldeblatt.guest.nationality.placeholder')"
                    class="col-span-12 md:col-span-6"
                    :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].nationality`)"
                    :validation="$v.meldeblatt.guests.$each[idx].nationality"
                    @blur="touch(`meldeblatt.guests.$each[${idx}].nationality`)"
                  />
                  <input-date
                    v-model="guest.dateOfBirth"
                    name="dateOfBirth"
                    :locale="locale"
                    :legacy="legacy"
                    :disabled="disabled"
                    :label="$t('meldeblatt.guest.dateOfBirth.label')"
                    :placeholder="$t('meldeblatt.guest.dateOfBirth.placeholder')"
                    :optional="!meldeblatt.configuration.requireBirthDate && !meldeblatt.configuration.hasGuestCard"
                    class="col-span-12 md:col-span-6"
                    :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].dateOfBirth`)"
                    :validation="$v.meldeblatt.guests.$each[idx].dateOfBirth"
                    @blur="touch(`meldeblatt.guests.$each[${idx}].dateOfBirth`)"
                  />
                  <checkbox
                    v-if="idx > 0"
                    v-model="guest.sameAddressAsPrimary"
                    :name="`sameAddressAsPrimary-${idx}`"
                    :disabled="disabled"
                    class="col-span-12"
                  >
                    <span class="text-dark">{{ $t('meldeblatt.guest.sameAddressAsPrimary.label') }}</span>
                    <template v-if="primaryGuestAddressComplete">
                      ({{ meldeblatt.guests[0].address.address }}, {{ meldeblatt.guests[0].address.zipCode }}
                      {{ meldeblatt.guests[0].address.city }}, {{ meldeblatt.guests[0].address.country }})
                    </template>
                  </checkbox>
                  <template v-if="idx === 0 || !guest.sameAddressAsPrimary">
                    <hr class="border-t border-1 border-grayLight mb-24 block col-span-12 col-start-1" />
                    <input-text
                      v-model="guest.address.zipCode"
                      name="zipCode"
                      class="col-span-12 md:col-span-6 col-start-1"
                      :disabled="disabled"
                      :label="$t('meldeblatt.guest.zipCode.label')"
                      :placeholder="$t('meldeblatt.guest.zipCode.placeholder')"
                      :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].zipCode`)"
                      :validation="$v.meldeblatt.guests.$each[idx].zipCode"
                      @blur="touch(`meldeblatt.guests.$each[${idx}].zipCode`)"
                    ></input-text>
                    <input-text
                      v-model="guest.address.city"
                      name="city"
                      :disabled="disabled"
                      :label="$t('meldeblatt.guest.city.label')"
                      :placeholder="$t('meldeblatt.guest.city.placeholder')"
                      :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].city`)"
                      :validation="$v.meldeblatt.guests.$each[idx].city"
                      class="col-span-12 md:col-span-6"
                      @blur="touch(`meldeblatt.guests.$each[${idx}].city`)"
                    ></input-text>
                    <input-text
                      v-model="guest.address.address"
                      name="address"
                      :disabled="disabled"
                      :label="$t('meldeblatt.guest.address.label')"
                      :placeholder="$t('meldeblatt.guest.address.placeholder')"
                      :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].address`)"
                      :validation="$v.meldeblatt.guests.$each[idx].address"
                      class="col-span-12 md:col-span-6"
                      @blur="touch(`meldeblatt.guests.$each[${idx}].address`)"
                    ></input-text>
                    <input-country
                      v-model="guest.address.country"
                      :countries="[hotel.details.countryCode, ...map(meldeblatt.guests || [], 'nationality'), ...map(meldeblatt.guests.address || [], 'country')]"
                      name="country"
                      class="col-span-12 md:col-span-6"
                      :locale="locale"
                      :disabled="disabled"
                      :label="$t('meldeblatt.guest.country.label')"
                      :placeholder="$t('meldeblatt.guest.country.placeholder')"
                      :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].country`)"
                      :validation="$v.meldeblatt.guests.$each[idx].country"
                      @blur="touch(`meldeblatt.guests.$each[${idx}].country`)"
                    />
                  </template>
                  <template
                    v-if="idx === 0 && ((!isNil(guest.email) && !isNil(guest.phoneNumber)) && (!isEmpty(guest.email.emailAddress) || !isEmpty(guest.phoneNumber.phoneNumber)))"
                  >
                    <hr class="border-t border-1 border-grayLight mb-24 block col-span-12 col-start-1" />
                    <input-text
                      v-model="guest.email.emailAddress"
                      name="emailAddress"
                      :disabled="disabled"
                      :label="$t('meldeblatt.guest.emailAddress.label')"
                      :placeholder="$t('meldeblatt.guest.emailAddress.placeholder')"
                      optional
                      :invalid="isInvalid(`meldeblatt.guests[${idx}].email.emailAddress`)"
                      :validation="$v.meldeblatt.guests[idx].email.emailAddress"
                      class="col-span-12 md:col-span-6"
                      @blur="touch(`meldeblatt.guests[${idx}].email.emailAddress`)"
                    ></input-text>
                    <input-text
                      v-model="guest.phoneNumber.phoneNumber"
                      name="phoneNumber"
                      :disabled="disabled"
                      :label="$t('meldeblatt.guest.phoneNumber.label')"
                      :placeholder="$t('meldeblatt.guest.phoneNumber.placeholder')"
                      optional
                      :invalid="isInvalid(`meldeblatt.guests[${idx}].phoneNumber.phoneNumber`)"
                      :validation="$v.meldeblatt.guests[idx].phoneNumber.phoneNumber"
                      class="col-span-12 md:col-span-6"
                      @blur="touch(`meldeblatt.guests[${idx}].phoneNumber.phoneNumber`)"
                    ></input-text>
                  </template>
                  <template
                    v-if="idx === 0 || (meldeblatt.configuration.travelDocumentRequiredAdditionalGuests && idx > 0)"
                  >
                    <template
                      v-if="(meldeblatt.configuration.travelDocumentRequiredForeigners && (guest.nationality || guest.address.country) !== hotel.details.countryCode)
                        || (meldeblatt.configuration.travelDocumentRequiredLocals && (guest.nationality || guest.address.country) !== hotel.details.countryCode)"
                    >
                      <template v-if="!isNil(guest.nationality || guest.address.country)">
                        <hr class="border-t border-1 border-grayLight mb-24 block col-span-12 col-start-1" />
                        <p v-if="['AT', 'DE'].includes(hotel.details.countryCode)" class="col-span-12">
                          {{ $t(`travelDocumentInfo.${hotel.details.countryCode}`) }}
                        </p>
                        <input-select
                          v-model="guest.document.type"
                          name="documentType"
                          :values="documentTypes"
                          :disabled="disabled"
                          :label="$t('meldeblatt.guest.document.type.label')"
                          :placeholder="$t('meldeblatt.guest.document.type.placeholder')"
                          :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].travelDocumentType`)"
                          :validation="$v.meldeblatt.guests.$each[idx].travelDocumentType"
                          class="col-span-12 md:col-span-6"
                          @blur="touch(`meldeblatt.guests.$each[${idx}].travelDocumentType`)"
                        />
                        <input-text
                          v-model="guest.document.number"
                          name="documentNumber"
                          :disabled="disabled"
                          :label="$t('meldeblatt.guest.document.number.label')"
                          :placeholder="$t('meldeblatt.guest.document.number.placeholder')"
                          :invalid="isInvalid(`meldeblatt.guests.$each[${idx}].travelDocumentNumber`)"
                          :validation="$v.meldeblatt.guests.$each[idx].travelDocumentNumber"
                          class="col-span-12 md:col-span-6"
                          @blur="touch(`meldeblatt.guests.$each[${idx}].travelDocumentNumber`)"
                        />
                        <input-text
                          v-model="guest.document.issuingAuthority"
                          name="documentIssuingAuthority"
                          :disabled="disabled"
                          :label="$t('meldeblatt.guest.document.issuingAuthority.label')"
                          :placeholder="$t('meldeblatt.guest.document.issuingAuthority.placeholder')"
                          class="col-span-12 md:col-span-6"
                        />
                        <input-date
                          v-model="guest.document.issueDate"
                          name="documentIssueDate"
                          :legacy="legacy"
                          :locale="locale"
                          :disabled="disabled"
                          :label="$t('meldeblatt.guest.document.issueDate.label')"
                          :placeholder="$t('meldeblatt.guest.document.issueDate.placeholder')"
                          class="col-span-12 md:col-span-6"
                        />
                        <input-date
                          v-model="guest.document.expiryDate"
                          name="documentExpiryDate"
                          :legacy="legacy"
                          :locale="locale"
                          :disabled="disabled"
                          :label="$t('meldeblatt.guest.document.expiryDate.label')"
                          :placeholder="$t('meldeblatt.guest.document.expiryDate.placeholder')"
                          min-date="2000-01-01"
                          max-date="2099-12-31"
                          class="col-span-12 md:col-span-6"
                        />
                      </template>
                    </template>
                  </template>
                  <hr
                    v-if="(idx + 1) < meldeblatt.guests.length"
                    class="border-t border-1 border-grayLight mb-24 block col-span-12 col-start-1"
                  />
                </div>
              </template>
              <div
                v-if="meldeblatt.configuration.digitalSignature"
                v-in-viewport="{ margin: '-49% 0px -49% 0px' }"
                class="col-span-12 grid grid-cols-12 gap-24"
                data--section="signature"
                style="padding-bottom:10vh;"
              >
                <hr class="border-t border-1 border-grayLight mb-24 block col-span-12 col-start-1" />
                <h2 :id="`section-signature`" class="h2 mb-16 col-span-12">
                  {{ $t('meldeblatt.signature.headline') }}
                </h2>
                <div class="col-span-12 md:col-span-9 lg:col-span-12 2xl:col-span-6">
                  <input-signature
                    v-model="meldeblatt.signatureBase64Image"
                    :disabled="!canSign"
                    :label="$t('meldeblatt.signature.headline')"
                    :name="primaryGuestName"
                  />
                </div>
                <p class="col-span-12">
                  {{ $t('meldeblatt.signature.description') }}
                </p>
                <p v-if="hotel.details.countryCode === 'DE'" class="col-span-12">
                  {{ $t('meldeblatt.signature.DE') }}
                </p>
              </div>
            </div>
            <button-floating v-if="!complete" wide disabled @click="validate">
              {{ $t('meldeblatt.button.requiredFieldsMissing') }}
            </button-floating>
            <button-floating v-else-if="complete && !signed" wide disabled @click="onClickSection('signature')">
              {{ $t('meldeblatt.button.sign') }}
            </button-floating>
            <button-floating v-else wide @click="onClickSave">
              {{ $t('meldeblatt.button.send') }}
            </button-floating>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, isFunction, isNil, isNumber, toNumber } from 'lodash/lang';
import ButtonSquare from '@peda/hm-public-vue-component-library/src/components/ButtonSquare/ButtonSquare';
import ButtonBig from '@peda/hm-public-vue-component-library/src/components/ButtonBig/ButtonBig';
import InputSignature from '@/components/inputs/InputSignature';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import KioskMixin from '@/mixins/KioskMixin';
import Loading from '@peda/hm-public-vue-component-library/src/components/Loading';
import InputSelect from '@peda/hm-public-vue-component-library/src/components/InputElements/InputSelect/InputSelect';
import InputCountry from '@peda/hm-public-vue-component-library/src/components/InputElements/InputCountry/InputCountry';
import LocaleMixin from '@/mixins/LocaleMixin';
import Checkbox from '@peda/hm-public-vue-component-library/src/components/InputElements/Checkbox/Checkbox';
import InputDate from '@peda/hm-public-vue-component-library/src/components/InputElements/InputDate/InputDate';
import { head } from 'lodash/array';
import fecha from 'fecha';
import TimerMixin from '@peda/hm-public-vue-component-library/src/mixins/TimerMixin';
import { email, helpers, minLength, required, requiredIf } from 'vuelidate/lib/validators';
import { map, size } from 'lodash/collection';
import { debounce } from 'lodash/function';
import ButtonFloating from '@peda/hm-public-vue-component-library/src/components/ButtonFloating/ButtonFloating';
import scrollIntoView from 'scroll-into-view-if-needed';
import FormatDateRangeMixin from '@/mixins/FormatDateRangeMixin';
import LanguageModal from '@peda/hm-public-vue-component-library/src/components/Modal/LanguageModal';

export default {
  name: 'MeldeblattView',

  components: {
    ButtonFloating,
    InputSelect,
    InputText,
    InputSignature,
    InputCountry,
    InputDate,
    ButtonBig,
    ButtonSquare,
    Loading,
    Checkbox,
    LanguageModal,
  },

  mixins: [KioskMixin, LocaleMixin, TimerMixin, FormatDateRangeMixin],
  props: {
    msg: String,
  },

  data: function () {
    return {
      hotelId: null,
      meldeblatt: null,
      meldeblattId: null,
      hmac: null,
      save: null,
      timeoutTimer: null,
    };
  },

  computed: {
    legacy() {
      // if (['b3ae84c4af'].includes(this.hotelId)) return false;
      // else return true;
      return false;
    },
    languages: function () {
      return this.$store.getters.languages || ['de', 'en'];
    },
    primaryGuestName() {
      if (this.meldeblatt && !isEmpty(this.meldeblatt.guests)) {
        const primaryGuest = this.meldeblatt.guests[0];

        const guestName = `${primaryGuest.namePrefix || ''} ${primaryGuest.firstName || ''} ${primaryGuest.lastName || ''} ${primaryGuest.nameSuffix || ''}`.trim();

        if (isEmpty(guestName)) return '-';
        else return guestName;
      }

      return '-';
    },
    ws() {
      return this.$store.getters.ws;
    },
    disabled() {
      return this.meldeblatt && !isEmpty(this.meldeblatt.signatureBase64Image);
    },
    complete() {
      return this.canSign;
    },
    signed() {
      if (this.meldeblatt && this.meldeblatt.configuration && !this.meldeblatt.configuration.digitalSignature) return true;
      return this.complete && !isEmpty(this.meldeblatt.signatureBase64Image);
    },
    canSign() {
      if (this.completedGuests.length === 0) return false;

      return this.completedGuests.length === this.meldeblatt.guests.length;
    },
    genders() {
      return [
        {
          code: 'Male',
          label: this.$t('Gender.Male'),
        },
        {
          code: 'Female',
          label: this.$t('Gender.Female'),
        },
        {
          code: 'Other',
          label: this.$t('Gender.Other'),
        },
      ];
    },
    hotel() {
      return this.$store.getters.hotel;
    },
    documentTypes() {
      return [
        {
          code: 'Passport',
          label: this.$t('TravelDocumentType.Passport'),
        },
        {
          code: 'PassportCard',
          label: this.$t('TravelDocumentType.PassportCard'),
        },
        {
          code: 'DriversLicense',
          label: this.$t('TravelDocumentType.DriversLicense'),
        },
        {
          code: 'PermanentResidentCard',
          label: this.$t('TravelDocumentType.PermanentResidentCard'),
        },
        {
          code: 'Visa',
          label: this.$t('TravelDocumentType.Visa'),
        },
      ];
    },
    completedGuests() {
      if (isNil(this.meldeblatt) || this.isNil(this.meldeblatt.guests)) return [];

      return this.meldeblatt.guests
        .map((guest, idx) => {
          let ret = true;
          if (!isNil(this.$v.meldeblatt.guests[idx])) {
            ret = ret && !this.$v.meldeblatt.guests[idx].$invalid;
          }
          if (!isNil(this.$v.meldeblatt.guests.$each[idx])) {
            ret = ret && !this.$v.meldeblatt.guests.$each[idx].$invalid;
          }
          return ret ? idx : null;
        })
        .filter((it) => it != null);
    },
    completedRecords() {
      const guests = this.completedGuests;
      const additional = [];
      if (isNil(this.meldeblatt)) return [];
      if (!isEmpty(this.meldeblatt.signatureBase64Image)) {
        additional.push('signature');
      }

      return [
        ...(guests || []),
        ...(additional || []),
      ];
    },
    primaryGuestAddressComplete() {
      if (isNil(this.meldeblatt) || this.isEmpty(this.meldeblatt.guests)) return false;

      const primaryGuest = this.meldeblatt.guests[0].address;

      if (isEmpty(primaryGuest.country)) return false;
      if (isEmpty(primaryGuest.city)) return false;
      if (isEmpty(primaryGuest.address)) return false;

      return true;
    },
  },

  watch: {
    meldeblatt: {
      handler: function (newVal) {
        if (!isNil(this.timeoutTimer)) {
          this.clearTimeout(this.timeoutTimer);
          this.timeoutTimer = null;
        }
        if (isNil(this.timeoutTimer)) {
          this.timeoutTimer = this.setTimeout(this.closeMeldeblatt, 1000 * 60 * 15); // 15 minutes of inactivity
        }
        this.$store.commit('kiosk.meldeblatt', this.meldeblatt);
        if (!isNil(newVal)) this.save();
      },
      deep: true,
    },
    completedRecords(newVal) {
      this.$serviceBus.$emit(
        'kiosk.meldeblatt.completeRecords',
        [
          ...(newVal || []),
        ],
      );
    },
  },

  beforeDestroy() {
    this.$store.commit('kiosk.meldeblatt', null);
    this.$serviceBus.$off('nav.clickGuest', this.onClickGuest);
    this.$serviceBus.$off('nav.clickSection', this.onClickSection);
    this.$serviceBus.$off('kiosk.ws.onmessage', this.onMessage);

    if (!isNil(this.hotelId) && !isNil(this.meldeblattId) && !isNil(this.hmac) && !isNil(this.meldeblatt)) this._save();
  },

  created() {
    this.hotelId = this.$route.params.encodedHotelId;
    this.meldeblattId = this.$route.params.encodedMeldeblattId;
    this.hmac = this.$route.params.hmac;

    const confirmationNumber = this.$route.params.confirmationNumber;
    const pinCode = this.$route.params.pinCode;

    if (isEmpty(this.meldeblattId) && isEmpty(this.hmac) && !isEmpty(confirmationNumber) && !isEmpty(pinCode)) {
      this.$superagent
        .post(this.$serviceUrls.reservationGroup.meldeblatt(this.hotelId, confirmationNumber, pinCode))
        .then((res) => {
          this.$router.push({
            name: 'portal.meldeblatt',
            params: {
              encodedHotelId: this.hotelId,
              confirmationNumber: confirmationNumber,
              pinCode: pinCode,
              encodedMeldeblattId: res.body.data.meldeblattId,
              hmac: res.body.data.hmac,
            },
          });
        })
        .catch((err) => {
          // in case meldeblatt could not be created, reservation is departed already, ...
          this.$router.push({
            name: 'portal.reservationGroup',
            params: {
              encodedHotelId: this.hotelId,
              confirmationNumber: confirmationNumber,
              pinCode: pinCode,
            },
          });
        });
    } else {
      this.$serviceBus.$on('nav.clickGuest', this.onClickGuest);
      this.$serviceBus.$on('nav.clickSection', this.onClickSection);
      this.$serviceBus.$on('kiosk.ws.onmessage', this.onMessage);
      this.loadData();

      this.setInterval(this.updateNav, 1000);

      this.save = debounce(this._save, 1000);

      this.sendMeldeblattOpenInformation();
    }
  },

  validations() {
    const ret = {
      meldeblatt: {
        guests: {
          0: {
            email: {
              emailAddress: {
                email,
              },
            },
            phoneNumber: {
              phoneNumber: {
                custom: helpers.withParams(
                  {
                    type: 'phone',
                    message_key: 'phoneNumber',
                  },
                  (val, _) => {
                    if (isEmpty(val)) {
                      return true;
                    } else {
                      return !isNil(val.match(/^\+[0-9\-\s/]+$/));
                    }
                  },
                ),
                minLength: minLength(5),
              },
            },
          },
          $each: {
            travelDocumentNumber: {
              custom: helpers.withParams(
                { message_key: 'required' },
                (_, guest) => {
                  const idx = this.meldeblatt.guests.indexOf(guest);
                  if (idx === 0 || this.meldeblatt.configuration.travelDocumentRequiredAdditionalGuests) {
                    if ((this.meldeblatt.configuration.travelDocumentRequiredForeigners && (guest.nationality || guest.address.country) !== this.hotel.details.countryCode)
                          || (this.meldeblatt.configuration.travelDocumentRequiredLocals && (guest.nationality || guest.address.country) !== this.hotel.details.countryCode)) {
                      return !isEmpty(guest.document.number);
                    }
                  }

                  return true;
                },
              ),
            },
            travelDocumentType: {
              custom: helpers.withParams(
                { message_key: 'required' },
                (_, guest) => {
                  const idx = this.meldeblatt.guests.indexOf(guest);
                  if (idx === 0 || this.meldeblatt.configuration.travelDocumentRequiredAdditionalGuests) {
                    if ((this.meldeblatt.configuration.travelDocumentRequiredForeigners && (guest.nationality || guest.address.country) !== this.hotel.details.countryCode)
                          || (this.meldeblatt.configuration.travelDocumentRequiredLocals && (guest.nationality || guest.address.country) !== this.hotel.details.countryCode)) {
                      return !isEmpty(guest.document.type);
                    }
                  }

                  return true;
                },
              ),
            },
            lastName: {
              required,
              minLength: minLength(2),
            },
            gender: {
              required,
            },
            nationality: {
              required,
            },
            dateOfBirth: {
              required: requiredIf((_) => this.meldeblatt.configuration.requireBirthDate || this.meldeblatt.configuration.hasGuestCard),
            },
            zipCode: {
              // zipCode is on the guest level (and not on the address level) so we can access the guest inside the custom validation
              custom: helpers.withParams(
                { message_key: 'zipCode' },
                (_, guest) => {
                  if (guest.sameAddressAsPrimary) return true;

                  const value = guest.address.zipCode;

                  if (['AT', 'DE'].includes(this.hotel.details.countryCode)) {
                    switch (guest.address.country) {
                      case 'AT':
                        return !isEmpty(value) && isNumber(toNumber(value)) && size(value) === 4;
                      case 'DE':
                        return !isEmpty(value) && isNumber(toNumber(value)) && size(value) === 5;
                      case 'CH':
                        if (this.hotel.details.countryCode === 'CH') {
                          return !isEmpty(value);
                        } else {
                          return true;
                        }
                      case 'ES':
                      case 'PT':
                        if (this.hotel.details.countryCode === 'ES') {
                          return !isEmpty(value);
                        } else {
                          return true;
                        }
                      default:
                        return true;
                    }
                  } else {
                    return true;
                  }
                },
              ),
            },
            city: {
              custom: helpers.withParams(
                { message_key: 'required' },
                (value, guest) => {
                  if (guest.sameAddressAsPrimary) return true;
                  else return !isEmpty(guest.address.city);
                },
              ),
            },
            address: {
              custom: helpers.withParams(
                { message_key: 'required' },
                (value, guest) => {
                  if (guest.sameAddressAsPrimary) return true;
                  else return !isEmpty(guest.address.address);
                },
              ),
            },
            country: {
              custom: helpers.withParams(
                { message_key: 'required' },
                (value, guest) => {
                  if (guest.sameAddressAsPrimary) return true;
                  else return !isEmpty(guest.address.country);
                },
              ),
            },
          },
        },
      },
    };

    if (!isNil(this.meldeblatt) && !isNil(this.meldeblatt.configuration)) {
      if (this.meldeblatt.configuration.requireBirthDate || this.meldeblatt.configuration.hasGuestCard) {
        ret.meldeblatt.guests.$each.dateOfBirth = {
          required,
        };
      }
    }

    return ret;
  },

  methods: {
    map,
    isNil,
    isEmpty,
    closeMeldeblatt() {
      try {
        if (this.ws && this.ws.readyState === 1) {
          this.ws.send(JSON.stringify({
            action: 'ui.request::UiCloseInformation',
            id: this.meldeblattId,
            className: 'MeldeblattHolder',
            tabId: null,
            hotelId: this.hotelId,
            user: 'guest@kiosk',
          }));
        }
      } catch (e) { /* ignore */ }

      if (this.$route.meta && this.$route.meta.kiosk) {
        this.$router.push({
          name: 'kiosk.hotel',
          params: { encodedHotelId: this.$route.params.encodedHotelId },
        });
      } else {
        const confirmationNumber = this.$route.params.confirmationNumber;
        const pinCode = this.$route.params.pinCode;
        this.$store.commit('portal.reservationGroup', null);
        this.$serviceBus.$emit('meldeblatt.update');

        this.$router.push({
          name: 'portal.reservationGroup',
          params: {
            encodedHotelId: this.hotelId,
            confirmationNumber: confirmationNumber,
            pinCode: pinCode,
          },
        });
      }
    },
    onClickGuest(idx) {
      const selector = `#guest-${idx}`;
      const result = this.$el.querySelector(selector);
      if (result) {
        result.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        this.setTimeout(this.updateNav, 250);
      }
    },
    onClickSection(idx) {
      const selector = `#section-${idx}`;
      const result = this.$el.querySelector(selector);
      if (result) {
        result.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        this.setTimeout(this.updateNav, 250);
      }
    },
    loadData() {
      this.$superagent
        .get(this.$serviceUrls.meldeblatt.get(this.hotelId, this.meldeblattId, this.hmac))
        .then((res) => {
          const tmp = res.body.data;

          if (!isNil(tmp)) {
            tmp.guests.forEach((it) => {
              if (isEmpty(it.address.zipCode) && isEmpty(it.address.address) && isEmpty(it.address.city)) {
                it.sameAddressAsPrimary = true;
              }
            });

            if (tmp.guests.length >= 1) {
              tmp.guests[0].sameAddressAsPrimary = false;
            }
          }

          this.$serviceBus.$emit('meldeblatt.open');

          this.meldeblatt = tmp;
        })
        .catch((err) => {
        });
    },
    validate(scroll = true) {
      this.$v.$touch();

      if (scroll) {
        if (this.$v.$invalid) {
          this.setTimeout(() => {
            const invalidElement = this.$el.querySelector('label.invalid');
            if (invalidElement) {
              scrollIntoView(
                invalidElement,
                {
                  // scrollMode: 'if-needed',
                  scrollMode: 'always',
                  block: 'center',
                  inline: 'center',
                  behavior: 'smooth',
                },
              );
            }
          }, 250);
        }
      }
    },
    isInvalid(fieldName) {
      // return !isNil(this.$v.contactData[fieldName]) && this.$v.contactData[fieldName].$dirty && this.$v.contactData[fieldName].$invalid;
      let current = this.$v;
      const split = fieldName.split(/[.[\]]+/);

      // eslint-disable-next-line no-restricted-syntax
      for (const part of split) {
        // eslint-disable-next-line no-continue
        if (isEmpty(part)) continue;
        if (isNil(current)) return false;
        current = current[part];
      }

      if (current) {
        return current.$dirty && current.$invalid;
      } else {
        return false;
      }
    },
    touch(fieldName) {
      // return !isNil(this.$v.contactData[fieldName]) && this.$v.contactData[fieldName].$dirty && this.$v.contactData[fieldName].$invalid;
      let current = this.$v;
      const split = fieldName.split(/[.[\]]+/);

      // eslint-disable-next-line no-restricted-syntax
      for (const part of split) {
        // eslint-disable-next-line no-continue
        if (isEmpty(part)) continue;
        if (isNil(current)) return false;
        current = current[part];
      }

      if (current) current.$touch();

      return true;
    },
    _save() {
      const meldeblatt = cloneDeep(this.meldeblatt);

      if (isNil(this.hotelId) || isNil(this.meldeblattId) || isNil(this.hmac) || isNil(meldeblatt)) return Promise.reject(new Error('cancelling save - meldeblatt not loaded'));

      return this.$superagent
        .put(this.$serviceUrls.meldeblatt.get(this.hotelId, this.meldeblattId, this.hmac))
        .send(meldeblatt)
        .then((res) => res)
        .catch((err) => {
          this.$rollbar.error(err);
        });
    },
    updateNav() {
      if (isNil(this.meldeblatt)) return;

      const guestsInViewport = Object.values(this.$el.querySelectorAll('.in-viewport[data--guest]'))
        .map((it) => parseInt(it.getAttribute('data--guest'), 10));
      const sectionsInViewport = Object.values(this.$el.querySelectorAll('.in-viewport[data--section]'))
        .map((it) => it.getAttribute('data--section') || 'unknown');
      this.$serviceBus.$emit(
        'kiosk.meldeblatt.activeRecords',
        [
          ...(guestsInViewport || []),
          ...(sectionsInViewport || []),
        ],
      );

      if ((sectionsInViewport || []).includes('signature')) {
        this.validate(false);
      }
    },
    onClickSave() {
      this.save()
        .then(() => {
          this.$swal({
            title: this.$t('success'),
            // 'Daten wurden gespeichert!',
            icon: 'success',
            timer: 5000,
          });
          this.setTimeout(this.closeMeldeblatt, 5000);
        });
    },
    onMessage(event) {
      const data = JSON.parse(event.data);

      if (isNil(data) || isNil(data.data) || isNil(data.data.action)) {
        return;
      }

      switch (data.data.action) {
        case 'ui.request::UiHasOpenRequest':
          if (data.data.className === 'MeldeblattHolder' && data.data.hotelId === this.hotelId && data.data.id === this.meldeblattId) {
            this.sendMeldeblattOpenInformation(data.data.tabId);
          }
          break;
        case 'ui.request::UiCloseRequest':
          if (data.data.className === 'MeldeblattHolder' && data.data.hotelId === this.hotelId && data.data.id === this.meldeblattId) {
            this.save()
              .finally(() => {
                this.closeMeldeblatt();
              });
          }
          break;
        default:
          // do nothing (at least for now);
          break;
      }
    },
    sendMeldeblattOpenInformation(targetTabId) {
      if (this.ws && this.ws.readyState === 1) {
        this.ws.send(JSON.stringify({
          action: 'ui.request::UiHasOpenResponse',
          id: this.meldeblattId,
          className: 'MeldeblattHolder',
          tabId: null,
          targetTabId: targetTabId || null,
          hotelId: this.hotelId,
          user: 'guest@kiosk',
        }));
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

</style>
