<template>
  <div
    class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-60 overflow-hidden flex flex-col items-center justify-center backdrop-blur-md"
    @click="$emit('close')"
  >
    <div class="picker__wrapper px-32 py-32" @click.stop @mouseup.stop @mousedown.stop>
      <div class="picker">
        <div class="picker-window"></div>
        <span class="btn--scroll flex justify-center text-grayBlue hover:text-primary cursor-pointer" @click="moveWindow('day', -1)">
          <chevron-up-icon size="1.5x" />
        </span>
        <span class="btn--scroll flex justify-center text-grayBlue hover:text-primary cursor-pointer" @click="moveWindow('month', -1)">
          <chevron-up-icon size="1.5x" />
        </span>
        <span class="btn--scroll flex justify-center text-grayBlue hover:text-primary cursor-pointer" @click="moveWindow('year', 1)">
          <chevron-up-icon size="1.5x" />
        </span>
        <ul
          class="picker-day"
          @scroll="updateValue"
          @mouseenter="mouseenter($event, 'day')"
          @mousedown="mousedown($event, 'day')"
          @mouseleave="mouseleave($event, 'day')"
          @mouseup="mouseup($event, 'day')"
          @mousemove="mousemove($event, 'day')"
        >
          <li v-for="day in days">
            {{ day }}
          </li>
        </ul>
        <ul
          class="picker-month"
          @scroll="updateValue"
          @mouseenter="mouseenter($event, 'month')"
          @mousedown="mousedown($event, 'month')"
          @mouseleave="mouseleave($event, 'month')"
          @mouseup="mouseup($event, 'month')"
          @mousemove="mousemove($event, 'month')"
        >
          <li v-for="month in months">
            {{ month }}
          </li>
        </ul>
        <ul
          class="picker-year"
          @scroll="updateValue"
          @mouseenter="mouseenter($event, 'year')"
          @mousedown="mousedown($event, 'year')"
          @mouseleave="mouseleave($event, 'year')"
          @mouseup="mouseup($event, 'year')"
          @mousemove="mousemove($event, 'year')"
        >
          <li v-for="year in years">
            {{ year }}
          </li>
        </ul>
        <span class="btn--scroll flex justify-center text-grayBlue hover:text-primary cursor-pointer" @click="moveWindow('day', 1)">
          <chevron-down-icon size="1.5x" />
        </span>
        <span class="btn--scroll flex justify-center text-grayBlue hover:text-primary cursor-pointer" @click="moveWindow('month', 1)">
          <chevron-down-icon size="1.5x" />
        </span>
        <span class="btn--scroll flex justify-center text-grayBlue hover:text-primary cursor-pointer" @click="moveWindow('year', -1)">
          <chevron-down-icon size="1.5x" />
        </span>
      </div>
      <div class="button-container px-16">
        <button-small v-if="isValidDate" @click="confirm">
          <span>{{ formattedDate }}</span>
        </button-small>
        <button-small v-else disabled @click="confirm">
          <span>Datum auswählen</span>
        </button-small>
      </div>
    </div>
  </div>
</template>

<script>

import TimerMixin from '../../../mixins/TimerMixin';
import fecha from 'fecha';
import { isNil, isEmpty } from 'lodash/lang';
import InputMixin from '../../../mixins/InputMixin';
import ButtonSmall from '../../ButtonSmall/ButtonSmall';
import { ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons';

export default {
  name: 'DatePicker',

  components: { ButtonSmall, ChevronUpIcon, ChevronDownIcon },

  mixins: [TimerMixin, InputMixin],

  props: {
    value: String,
    locale: String,
    minDate: {
      type: String,
      default: '1900-01-01',
    },
    maxDate: {
      default: () => new Date(),
    },
  },

  data: function () {
    return {
      internalValue: null,
      isDown: {
        day: false,
        month: false,
        year: false,
      },
      startY: {
        day: 0,
        month: 0,
        year: 0,
      },
      scrollTop: {
        day: 0,
        month: 0,
        year: 0,
      },
      dateValue: {
        day: 1,
        month: 1,
        year: 2023,
      },
      mounted: false,
      mouseOverElement: null,
    };
  },

  computed: {
    years: function () {
      const maxYear = new Date(this.maxDate).getFullYear();
      const minYear = new Date(this.minDate).getFullYear();
      const ret = [];
      for (let i = maxYear; i >= minYear; i--) {
        ret.push(i);
      }

      return ret;
    },
    months: function () {
      return this.getMonthNames(this.locale.toLowerCase().substring(0, 2), 'short');
    },
    days: function () {
      const ret = [];
      for (let i = 1; i <= 31; i++) {
        ret.push(i);
      }
      return ret;
    },
    date: function () {
      return fecha.parse(`${this.dateValue.year}-${this.dateValue.month}-${this.dateValue.day}`, 'YYYY-M-D');
    },
    formattedDate: function () {
      if (isNil(this.date)) return '';
      const format = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      };
      return new Intl.DateTimeFormat(this.locale, format).format(this.date);
    },
    isValidDate: function () {
      return !isNil(this.date);
    },
  },

  watch: {
    date: function () {
      this.internalValue = this.date;
    },
    internalValue: function () {
    },
  },

  mounted() {
    this.mounted = true;
    if (!isNil(this.internalValue)) {
      this.$set(this.dateValue, 'day', this.internalValue.getDate());
      this.$set(this.dateValue, 'month', this.internalValue.getMonth() + 1);
      this.$set(this.dateValue, 'year', this.internalValue.getFullYear());
    }
    this.updateScrollPositions();
  },

  beforeDestroy() {
    this.mounted = false;

    this.$serviceBus.$off('shortcut.up', this.keyUp);
    this.$serviceBus.$off('shortcut.down', this.keyDown);
  },

  created: function () {
    this.internalValue = isEmpty(this.value) ? null : fecha.parse(this.value, 'YYYYMMDD');
    this.setInterval(this.updateValue, 25000);

    this.$serviceBus.$on('shortcut.up', this.keyUp);
    this.$serviceBus.$on('shortcut.down', this.keyDown);
  },

  methods: {
    updateScrollPositions() {
      if (!isNil(this.dateValue)) {
        ['day', 'month', 'year'].forEach((it) => {
          const value = this.dateValue[it];
          let idx = -1;
          switch (it) {
            case 'month':
            case 'day':
              this.$el.querySelector(`.picker-${it}`).scrollTop = value * 28 - 14;
              break;
            case 'year':
              idx = this[`${it}s`].indexOf(value);
              if (idx >= 0) {
                this.$el.querySelector(`.picker-${it}`).scrollTop = idx * 28 + 14;
              }
              break;
            default:
              break;
          }
        });
      }
    },
    moveWindow(type, offset) {
      let newYear = -1;
      let idx = -1;

      switch (type) {
        case 'day':
          if (this.dateValue[type] + offset >= 1 && this.dateValue[type] + offset <= 31) {
            this.dateValue[type] += offset;
            this.updateScrollPositions();
          }
          break;
        case 'month':
          if (this.dateValue[type] + offset >= 1 && this.dateValue[type] + offset <= 12) {
            this.dateValue[type] += offset;
            this.updateScrollPositions();
          }
          break;
        case 'year':
          newYear = this.dateValue[type] + offset;
          idx = this[`${type}s`].indexOf(newYear);
          if (idx >= 0) {
            this.dateValue[type] = newYear;
            this.updateScrollPositions();
          }
          break;

        default:
          break;
      }
    },
    confirm() {
      if (!isEmpty(this.internalValue)) {
        this.$emit('input', null);
        this.$emit('input:value', null);
      } else {
        this.$emit('input', fecha.format(this.internalValue, 'YYYYMMDD'));
        this.$emit('input:value', fecha.format(this.internalValue, 'YYYYMMDD'));
      }
    },
    updateValue() {
      if (this.mounted) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        ['day', 'month', 'year'].forEach((it) => {
          const scrollTop = this.$el.querySelector(`.picker-${it}`).scrollTop;
          const idx = Math.max(0, Math.round((scrollTop - 14) / 28));
          switch (it) {
            case 'month':
              this.$set(this.dateValue, it, Math.min(12, (idx + 1)));
              break;
            case 'day':
              this.$set(this.dateValue, it, Math.min(31, (idx + 1)));
              break;
            case 'year':
              this.$set(this.dateValue, it, this[`${it}s`][Math.min(this[`${it}s`].length - 1, idx)] || this[`${it}s`][0]);
              break;
            default:
              break;
          }
        });
      }
    },
    getMonthNames(locale = 'en', format = 'long') {
      const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
        const mm = month < 10 ? `0${month}` : month;
        return new Date(`2017-${mm}-01T00:00:00+00:00`);
      });
      return months.map((date) => formatter.format(date));
    },
    mousedown(e, type) {
      const slider = this.$el.querySelector(`.picker-${type}`);
      this.isDown[type] = true;
      this.startY[type] = e.pageY - slider.offsetTop;
      this.scrollTop[type] = slider.scrollTop;
    },
    mouseleave(e, type) {
      this.isDown[type] = false;
    },
    mouseup(e, type) {
      this.isDown[type] = false;
    },
    mousemove(e, type) {
      const slider = this.$el.querySelector(`.picker-${type}`);
      if (!this.isDown[type]) return;
      e.preventDefault();
      const x = e.pageY - slider.offsetTop;
      const walk = (x - this.startY[type]) * 3;
      slider.scrollTop = this.scrollTop[type] - walk;
    },
    mouseenter($event, $type) {
      this.mouseOverElement = $type;
    },
    keypress($key) {
      if (isNil(this.mouseOverElement)) return;

      switch ($key) {
        case 'up':
          this.moveWindow(this.mouseOverElement, -1);
          break;
        case 'down':
          this.moveWindow(this.mouseOverElement, 1);
          break;
        default:
          // nothing
          break;
      }
    },
    keyUp() {
      this.keypress('up');
    },
    keyDown() {
      this.keypress('down');
    },
  },
};
</script>

<style lang="scss">
body:has(.picker__wrapper) {
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
@import '~@/styles/import';

.backdrop-blur {
  &-md {
    backdrop-filter: blur(2px);
    background-color: rgba(0,0,0, 0.05);
    overscroll-behavior: contain!important;
    overflow: hidden!important;
  }
}

@mixin hideScrollbars {
  -ms-overflow-style: none; // IE 10+
  &::-webkit-scrollbar {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

.picker-window {
  position: absolute;
  border-top: 1px solid darken($grayLight, 10);
  border-bottom: 1px solid darken($grayLight, 10);
  height: 1.4em;
  left: 0;
  top: calc(50% + 0.15em);
  transform: translateY(-65%);
  width: 100%;
  pointer-events: none;
}
$height: 8em;
.picker {

  min-width: 300px;
  //display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 28px max-content 28px;
  &__wrapper {
    position: fixed;
    z-index: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    border-top: 2rem solid white;
    border-bottom: 2rem solid white;
    background: white;
  }
  display: inline-grid;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  //grid-template-columns: repeat(4, fit-content(500px));
  font-size: calc(2.0rem);
  line-height: 1.4;
  font-feature-settings: "tnum";
  font-weight: 400;
  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(50% - 28px - 0.5em);
    pointer-events: none;
  }
  &:before {
    top: -.2em;
    margin-top: 28px;
    background: linear-gradient(white, rgba(white, .7))
  }
  &:after {
    bottom: -.2em;
    margin-bottom: 28px;
    background: linear-gradient(rgba(white, .7), white)
  }

  ul {
    @include hideScrollbars();
    max-height: $height;
    overflow-y: scroll;
    margin-right: 1em;
    scroll-snap-type: y mandatory;
    padding-bottom: calc($height - 2em);
    padding-top: calc($height - 2em);
    overscroll-behavior: contain;
    cursor: grab;
  }
  li {
    scroll-snap-align: center;
    height: 1.4em;
    text-align: right;
    word-spacing: .2em;
  }
}

//.picker-day {
//  padding-right: .5em;
//  span {
//    padding-left: .56em;
//  }
//}

.button-container {
  text-align: center;
  padding-top: 3rem;
  width: 100%;
}
</style>
