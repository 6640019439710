<template>
  <div>
    <div class="contact">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('kiosk.headline') }}
        </h1>
        <div class="body text-gray">
          <loading v-if="isEmpty(pairingKey) && !disconnected"></loading>
          <template v-else-if="isEmpty(pairingKey) && disconnected">
            <p>{{ $t('kiosk.expired') }}</p>
            <button-floating @click="_connect">
              {{ $t('kiosk.reload') }}
            </button-floating>
          </template>
          <template v-else>
            <p>{{ $t('kiosk.explainer') }}</p>
            <pre class="grid-cols-12 text-48 text-dark text-center p-6 border-1 mt-24 mb-24" v-html="pairingKey" />
          </template>

          <!--          <p>Pair:</p>-->
          <!--          <pre class="grid-cols-12" v-html="pair" />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNil } from 'lodash/lang';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import Loading from '@peda/hm-public-vue-component-library/src/components/Loading';
import ButtonFloating from '@peda/hm-public-vue-component-library/src/components/ButtonFloating/ButtonFloating';
import { debounce } from 'lodash/function';
import baseUrl from '@/lib/urls';

export default {
  name: 'KioskConnectionView',

  components: {
    ButtonFloating,
    Loading,
    InputText,
  },
  props: {
    msg: String,
  },

  data: function () {
    return {
      pairingKey: null,
      log: null,
      ws: null,
      disconnected: false,
      connect: debounce(this._connect, 30 * 1000, { leading: true }),
    };
  },

  head: {
    title: function () {
      try {
        return this.$root.$children[0].$options.head.title();
      } catch (e) {
        return 'Kiosk';
      }
    },
    link: function () {
      let parent = [];
      try {
        parent = this.$root.$children[0].$options.head.link() || [];
      } catch (e) { /* ignore */ }
      if (isEmpty(this.manifestUrl)) return [...parent];
      return [...parent, { id: 'manifest', rel: 'manifest', href: this.manifestUrl }];
    },
  },

  computed: {
    pair: function () {
      return this.$store.getters['kiosk.pair'](this.$route.params.encodedHotelId);
    },
    manifestUrl: function () {
      if (isEmpty(this.$route.params.encodedHotelId)) return null;
      return `${baseUrl}/ui/guest/portal/v2.0/${this.$route.params.encodedHotelId}/hotel/kiosk.webmanifest`;
    },
  },

  created() {
    this.$serviceBus.$on('kiosk.ws.onopen', this.onopen);
    this.$serviceBus.$on('kiosk.ws.onmessage', this.onmessage);
    this.$serviceBus.$on('kiosk.ws.onclose', this.onclose);
    this.$serviceBus.$on('kiosk.ws.onerror', this.onerror);

    const ws = this.$store.getters.ws;
    if (!isNil(ws) && ws.readyState === 1) {
      this.disconnect();
    }
    this.connect();
  },

  beforeDestroy() {
    this.$serviceBus.$off('kiosk.ws.onopen', this.onopen);
    this.$serviceBus.$off('kiosk.ws.onmessage', this.onmessage);
    this.$serviceBus.$off('kiosk.ws.onclose', this.onclose);
    this.$serviceBus.$off('kiosk.ws.onerror', this.onerror);
  },

  methods: {
    isNil,
    isEmpty,
    disconnect() {
      const ws = this.$store.getters.ws;

      if (!isNil(ws) && ws.readyState === 1) {
        ws.close();
      }
    },
    _connect() {
      const ws = this.$store.getters.ws;

      if (!isNil(ws) && ws.readyState === 1) {
        this.ws = ws;
        this.init();
      } else {
        this.ws = new WebSocket(`${process.env.VUE_APP_WS_URL}?version=${process.env.VUE_APP_DATE}&system=kiosk`);
        this.ws.onopen = (e) => { this.$serviceBus.$emit('kiosk.ws.onopen', e); };
        this.ws.onmessage = (e) => { this.$serviceBus.$emit('kiosk.ws.onmessage', e); };
        this.ws.onclose = (e) => { this.$serviceBus.$emit('kiosk.ws.onclose', e); };
        this.ws.onerror = (e) => { this.$serviceBus.$emit('kiosk.ws.onerror', e); };

        this.$store.commit('ws', this.ws);
      }
    },
    init() {
      if (this.ws) {
        if (isNil(this.pair)) {
          this.ws.send(JSON.stringify({ action: 'kiosk.init' }));
        } else if (this.$store.getters['kiosk.paired'] !== true) {
          this.ws.send(JSON.stringify(this.pair));
        }
      }
    },
    onopen(event) {
      this.init();
    },
    onmessage(event) {
      if (isEmpty(event) || isEmpty(event.data)) return;
      const data = JSON.parse(event.data);

      if (isNil(data) || isNil(data.data)) {
        return;
      }

      switch (data.data.action) {
        case 'kiosk.init':
          this.pairingKey = data.data.pairingKey;
          break;
        case 'kiosk.pair':
          this.$store.commit('kiosk.pair', data.data);
          this.ws.send(JSON.stringify(data.data));
          break;
        case 'kiosk.rejected':
          this.$store.commit('kiosk.pair', { hotelId: this.$route.params.encodedHotelId });
          this.$store.commit('kiosk.paired', false);
          break;
        case 'kiosk.paired':
          if (!isNil(this.pair) && this.$store.getters['kiosk.paired'] !== true) {
            this.$store.commit('kiosk.paired', true);
            this.$router.push({
              name: 'kiosk.hotel.ready',
              params: { encodedHotelId: this.pair.hotelId },
            });
          }
          break;
        default:
          break;
      }
    },
    onclose(event) {
      this.pairingKey = null;
      this.disconnected = true;
      this.$store.commit('ws', null);
      this.$nextTick(this.connect);
    },
    onerror(error) {

    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
