<template>
  <div class="mb-24">
    <label class="input-label text-14 text-dark font-medium mb-8 block" :class="[ {'invalid': invalid }]" :for="name">
      {{ label }} <span class="text-gray">{{ optional ? "(optional)" : "" }}</span>
    </label>

    <v-select
      :id="name"
      v-model="selectValue"
      :class="[ {'invalid': invalid }]"
      :name="name"
      :disabled="disabled"
      :placeholder="disabled ? '' : placeholder"
      :options="countryNames"
      :clearable="false"
      :select-on-tab="true"
    >
      <template slot="search" slot-scope="props">
        <input
          class="vs__search"
          v-bind="props.attributes"
          readonly
          v-on="props.events"
        />
      </template>
      <template slot="open-indicator">
        <chevron-down-icon size="1.5x" class="text-gray" />
      </template>
      <template slot="selected-option" slot-scope="props">
        <table>
          <tr>
            <td class="w-40">
              <img :src="`https://flagcdn.com/w40/${props.code.toLowerCase()}.png`" class="w-25 rounded-3" />
            </td><td>
              {{ props.label }}
            </td>
          </tr>
        </table>
      </template>
      <template slot="option" slot-scope="props">
        <table>
          <tr>
            <td class="w-40">
              <img :src="`https://flagcdn.com/w40/${props.code.toLowerCase()}.png`" class="w-25 rounded-3" />
            </td>
            <td>
              {{ props.label }}
            </td>
          </tr>
        </table>
      </template>
    </v-select>
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import vSelect from 'vue-select';
import { find, sortBy } from 'lodash/collection';
import { isEmpty, isNil } from 'lodash/lang';
import { ChevronDownIcon } from 'vue-feather-icons';
import InputMixin from '../../../mixins/InputMixin';
import ErrorMessage from '../ErrorMessage';

export default {
  components: {
    'v-select': vSelect,
    ChevronDownIcon,
    ErrorMessage,
  },

  mixins: [InputMixin],
  props: {
    value: String,
    placeholder: String,
    locale: String,
    countries: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      internalValue: null,
      selectValue: null,
    };
  },

  computed: {
    countryNames() {
      let ret = this.$countries.getNames(this.locale);
      ret = isEmpty(ret) ? this.$countries.getNames('en') : ret;

      ret = Object.entries(ret).map(([k, v]) => ({
        label: v,
        code: k,
      }));

      ret = sortBy(ret, [(it) => (this.countries || []).includes(it.code) ? 0 : 1, 'label']);

      return ret;
    },
  },

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
      this.selectValue = find(this.countryNames, (it) => it.code === newVal);
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
    selectValue: function (newVal) {
      this.internalValue = isNil(newVal) ? null : newVal.code;
    },
  },

  created: function () {
    this.internalValue = this.value;
    this.selectValue = find(this.countryNames, (it) => it.code === this.value);
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {

  },
};
</script>

<style lang="scss">
</style>
