import { isNil } from 'lodash/lang';

export default {

  created() {
    if (this.$route.meta && this.$route.meta.kiosk) {
      if (isNil(this.$store.getters.ws)) {
        this.$router.push({ name: 'kiosk.hotel', params: { encodedHotelId: this.$route.params.encodedHotelId } });
      }

      this.$serviceBus.$on('kiosk.ws.onopen', this.onopen);
      this.$serviceBus.$on('kiosk.ws.onmessage', this.onmessage);
      this.$serviceBus.$on('kiosk.ws.onclose', this.onclose);
      this.$serviceBus.$on('kiosk.ws.onerror', this.onerror);
    }
  },

  beforeDestroy() {
    if (this.$route.meta && this.$route.meta.kiosk) {
      this.$serviceBus.$off('kiosk.ws.onopen', this.onopen);
      this.$serviceBus.$off('kiosk.ws.onmessage', this.onmessage);
      this.$serviceBus.$off('kiosk.ws.onclose', this.onclose);
      this.$serviceBus.$off('kiosk.ws.onerror', this.onerror);
    }
  },

  methods: {
    onopen(event) {
    },
    onmessage(event) {
      const data = JSON.parse(event.data);

      if (isNil(data) || isNil(data.data)) {
        return;
      }

      switch (data.data.action) {
        case 'kiosk.init':
          break;
        case 'kiosk.meldeblatt':
          this.$router.push({
            name: 'kiosk.meldeblatt',
            params: {
              encodedHotelId: data.data.encodedHotelId,
              encodedMeldeblattId: data.data.meldeblattId,
              hmac: data.data.hmac,
            },
            query: {
              t: new Date().getTime(),
            },
          });
          break;
        default:
          break;
      }
    },
    onclose(event) {
      if (this.$route.meta && this.$route.meta.kiosk) {
        if (this.$route.name !== 'kiosk.hotel' && !isNil(this.$route.params.encodedHotelId)) this.$router.push({ name: 'kiosk.hotel', params: { encodedHotelId: this.$route.params.encodedHotelId } });
        else if (isNil(this.$route.params.encodedHotelId)) this.$router.push({ name: 'kiosk' });
      }
    },
    onerror(error) {
      if (this.$route.meta && this.$route.meta.kiosk) {
        if (this.$route.name !== 'kiosk.hotel' && !isNil(this.$route.params.encodedHotelId)) this.$router.push({ name: 'kiosk.hotel', params: { encodedHotelId: this.$route.params.encodedHotelId } });
        else if (isNil(this.$route.params.encodedHotelId)) this.$router.push({ name: 'kiosk' });
      }
    },
  },
};
