<template>
  <layout
    id="app"
    :key="$route.path"
    v-hotkey="hotkeyBinding"
    content-key="asdf"
    :loading="!loaded"
    :loaded="loaded"
    :style="colors"
    :wide="$route.meta.wide || false"
  >
    <template #nav>
      <router-view name="nav">
        <empty-nav />
      </router-view>
    </template>
    <template #default>
      <column1 :wide="$route.meta.wide || false" :back-button="$route.meta.back || false">
        <router-view :key="$route.fullPath">
          <h1>content goes here</h1>
        </router-view>
      </column1>
    </template>
    <template #column-2>
      <column2>
        <router-view name="col2">
        </router-view>
      </column2>
    </template>
  </layout>
</template>

<script>
import Layout from '@peda/hm-public-vue-component-library/src/components/Layout';
import Column1 from '@peda/hm-public-vue-component-library/src/components/Column1';
import Column2 from '@peda/hm-public-vue-component-library/src/components/Column2';
import EmptyNav from '@peda/hm-public-vue-component-library/src/components/EmptyNav';
import regionist from 'regionist/dist/regionist.es.polyfilled';
import { isEmpty, isNil } from 'lodash/lang';
import TimerMixin from '@peda/hm-public-vue-component-library/src/mixins/TimerMixin';
import LocaleMixin from '@/mixins/LocaleMixin';

export default {
  name: 'App',

  components: {
    EmptyNav,
    Layout,
    Column1,
    Column2,
  },

  mixins: [TimerMixin, LocaleMixin],

  data: function () {
    return {
      colors: {
        '--color-primary': '#25857a',
        '--color-secondary': '#d3e7c4',
        '--text-color': '#394045',
        '--gray': '#a1abb3',
      },
      hotkeyBinding: {
        up: ($event) => { this.$serviceBus.$emit('shortcut.up', $event); },
        down: ($event) => { this.$serviceBus.$emit('shortcut.down', $event); },
      },
    };
  },

  head: {
    title: function () {
      if (isNil(this.hotel) || isNil(this.hotel.hotel)) {
        return {
          inner: 'Hotel Reservation Expert',
          separator: null,
          complement: null,
        };
      } else {
        return {
          inner: `${this.hotel.hotel.hotelName}`,
          separator: '|',
          complement: this.hotel.details.city,
        };
      }
    },
    link: function () {
      if (isNil(this.hotel) || isNil(this.hotel.hotel)) {
        return [
          {
            rel: 'icon',
            href: '/favicon.ico',
            id: 'primary',
          },
        ];
      } else {
        return [
          {
            id: 'primary',
            rel: 'alternate icon',
            sizes: '96x96',
            type: 'image/png',
            href: `${this.hotel.details.icon || this.hotel.details.logo}?twic=v1/focus=auto/cover=96x96/quality-max=90/output=png`,
          },
        ];
      }
    },
  },

  computed: {
    hotelId() {
      return this.$route.params.encodedHotelId;
    },
    hotel() {
      return this.$store.getters.hotel;
    },
    loaded() {
      if (this.$route.params.encodedHotelId) {
        return !isNil(this.hotel);
      } else {
        return true;
      }
    },
  },

  watch: {
    hotelId: function () {
      if (this.$route.params.encodedHotelId) {
        this.$store.commit('hotelId', this.$route.params.encodedHotelId);
        this.$store.commit('hotel', null);
        this.setTimeout(() => {
          this.loadData();
        }, 500);
      } else {
        this.$store.commit('hotelId', null);
        this.$store.commit('hotel', null);
      }
    },
  },

  created() {
    this.$store.commit('hotelId', this.$route.params.encodedHotelId || null);
    this.$store.commit('serviceBus', this.$serviceBus);
    this.setTimeout(() => {
      this.loadData();
    }, 500);
    window.i18n = {
      locale: this.locale,
    };
    this.$store.commit('regionSettings', regionist.guess());
    this.$store.commit('language', this.$i18n.locale);

    try {
      this.$i18n.locale = localStorage.getItem('locale') || 'de';
    } catch (_) { /* ignore */ }

    try {
      document.querySelector('html').setAttribute('lang', localStorage.getItem('locale') || 'de');
    } catch (_) { /* ignore */ }
  },

  beforeDestroy() {
  },

  methods: {
    isNil,
    isEmpty,
    loadData() {
      if (isEmpty(this.hotelId)) {
        setTimeout(this.loadData, 250);
        return;
      }

      this.$superagent
        .get(this.$serviceUrls.hotel.get(this.hotelId))
        .query(`locale=${this.locale}`)
        .query(`cacheKey=${(!isNil(this.$route.query) && this.$route.query.debug === '1') ? (new Date().toISOString()) : 'default'}`)
        .then((res) => {
          this.$store.commit('hotel', res.body.data);
          this.$emit('updateHead');
          this.$serviceBus.$emit('cookieconsent.run');
        })
        .catch((err) => {
          this.$rollbar.error(err);
          this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToLoadHotelData'));
          this.$router.push({ name: 'error' });
        });

      const query = { ...this.$route.query };
      if (query.debug === '1') {
        delete query.debug;
        this.$router.replace({ query });
      }
    },
  },

  i18n: {
    messages: {
    },
    locale: 'de', // set locale
    fallbackLocale: ['en', 'de'],
    silentTranslationWarn: true,
  },
};
</script>

<style>
  html, body {
    overscroll-behavior: none;
  }
</style>
