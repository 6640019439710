var render = function render(){var _vm=this,_c=_vm._self._c;return _c('navigation',{attrs:{"step":_vm.step,"steps":_vm.steps,"back-button":_vm.$route.meta.back || false,"logo":_vm.hotel.details.logo,"name":_vm.hotel.hotel.hotelName,"white-label-url":_vm.hotel.hotel.whiteLabelUrl,"white-label-name":_vm.hotel.hotel.whiteLabelName,"languages":_vm.languages}},[_c('nav-item',{key:1,attrs:{"disabled":"","title":_vm.$t('openReservation.openReservation'),"to":{ name: 'home', params: { encodedHotelId: _vm.$route.params.encodedHotelId } },"complete":!_vm.isNil(_vm.confirmationNumber)},scopedSlots:_vm._u([{key:"complete",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('reservationSelected', [_vm.confirmationNumber]))+" ")]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('selectReservation'))+" ")]},proxy:true}])}),(!_vm.isNil(_vm.resGrp))?[_c('nav-item',{key:2,attrs:{"title":_vm.$t('reservationDetails'),"to":{
        name: 'portal.reservationGroup',
        params: {
          encodedHotelId: _vm.$route.params.encodedHotelId,
          confirmationNumber: _vm.$route.params.confirmationNumber,
          pinCode: _vm.$route.params.pinCode,
        }
      },"complete":!_vm.isNil(_vm.resGrp)},scopedSlots:_vm._u([{key:"complete",fn:function(){return [(!_vm.isNil(_vm.resGrp) && !_vm.isNil(_vm.resGrp.roomReservations))?[_vm._v(" "+_vm._s(_vm.resGrp.roomReservations.length)+" "+_vm._s(_vm.$tc('rooms', _vm.resGrp.roomReservations.length))),(_vm.resGrp.roomReservations.length > 0)?[_vm._v("  | "+_vm._s(_vm.formattedDateRange(_vm.resGrp.roomReservations[0].arrivalDate, _vm.resGrp.roomReservations[0].departureDate))+" ")]:_vm._e()]:_vm._e()]},proxy:true},{key:"default",fn:function(){return undefined},proxy:true}],null,false,1874184953)}),_vm._l((_vm.resGrp.paymentRequests),function(paymentRequest){return [_c('nav-item',{key:`payment-${paymentRequest.shortId}`,attrs:{"title":_vm.$t('payment'),"to":{
          name: 'portal.payment',
          params: {
            encodedHotelId: _vm.$route.params.encodedHotelId,
            confirmationNumber: _vm.$route.params.confirmationNumber,
            pinCode: _vm.$route.params.pinCode,
            paymentRequestShortId: paymentRequest.shortId,
          }
        },"failed":['Cancelled', 'Failed'].includes(paymentRequest.status),"complete":['Complete'].includes(paymentRequest.status)},scopedSlots:_vm._u([{key:"complete",fn:function(){return [_vm._v(" "+_vm._s(paymentRequest.currency || _vm.hotel.details.currency)+" "+_vm._s(_vm._f("toCurrencyDigits")(paymentRequest.amount))+" | "+_vm._s(_vm.$t(`PaymentRequestStatus.${paymentRequest.status}`))+" ")]},proxy:true},{key:"failed",fn:function(){return [(paymentRequest.expired)?[_vm._v(" "+_vm._s(_vm.$t('paymentRequestExpired', [_vm.formatDateTime(paymentRequest.expireAt, 'isoDateTime')]))),_c('br')]:[_vm._v(" "+_vm._s(paymentRequest.currency || _vm.hotel.details.currency)+" "+_vm._s(_vm._f("toCurrencyDigits")(paymentRequest.amount))+" | "+_vm._s(_vm.$t(`PaymentRequestStatus.${paymentRequest.status}`))+" ")]]},proxy:true},{key:"default",fn:function(){return [(_vm.resGrp.paymentRequests.filter(it => ['Initial'].includes(it.status)).length > 0)?[_vm._v(" "+_vm._s(_vm.$t('paymentRequired'))+" ")]:(_vm.resGrp.paymentRequests.filter(it => ['InProgress'].includes(it.status)).length > 0)?[_vm._v(" "+_vm._s(_vm.$t('paymentProcessing'))+" ")]:_vm._e()]},proxy:true}],null,true)})]}),_vm._l((_vm.resGrp.meldeblaetter),function(meldeblatt,meldeblattIdx){return [_c('nav-item',{key:`meldeblatt-${meldeblattIdx}`,attrs:{"title":_vm.$t('guestData'),"to":{
          name: 'portal.meldeblatt',
          params: {
            encodedHotelId: _vm.$route.params.encodedHotelId,
            confirmationNumber: _vm.$route.params.confirmationNumber,
            pinCode: _vm.$route.params.pinCode,
            encodedMeldeblattId: meldeblatt.id,
            hmac: meldeblatt.hmac,
          }
        },"complete":['Complete', 'CompleteSigned'].includes(meldeblatt.validationStatus) || ['Sent', 'Reservation', 'Departed', 'Deleted'].includes(meldeblatt.status)},scopedSlots:_vm._u([{key:"complete",fn:function(){return [_vm._v(" "+_vm._s(meldeblatt.personCount)+" "+_vm._s(_vm.$tc('persons', meldeblatt.personCount))+" | "+_vm._s(_vm.formattedDateRange(meldeblatt.arrivalDate, meldeblatt.departureDate))+" ")]},proxy:true},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('enterGuestData'))+" ")]},proxy:true}],null,true)})]})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }