<template>
  <div>
    <div class="contact">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('openReservation.headline') }}
        </h1>
        <div class="body text-gray">
          <div class="grid grid-cols-12 gap-24">
            <input-text
              v-model="confirmationNumber"
              name="confirmationNumber"
              :maxlength="8"
              :invalid="isInvalid('confirmationNumber')"
              :validation="$v.confirmationNumber"
              :label="$t('openReservation.confirmationNumber')"
              :placeholder="$t('openReservation.confirmationNumberPlaceholder')"
              class="col-span-12"
              @keyup.enter.native="openReservation"
              @blur="$v.confirmationNumber.$touch"
            ></input-text>
            <input-text
              v-model="pinCode"
              name="pinCode"
              min="0000"
              max="9999"
              :maxlength="4"
              :invalid="isInvalid('pinCode')"
              :validation="$v.pinCode"
              :label="$t('openReservation.pinCode')"
              :placeholder="$t('openReservation.pinCodePlaceholder')"
              class="col-span-12"
              @keyup.enter.native="openReservation"
              @blur="$v.pinCode.$touch"
            ></input-text>
          </div>
          <hotel-contact />
          <button-floating v-if="invalid" disabled @click="validate">
            {{ $t('openReservation.invalidConfirmationNumberOrPin') }}
          </button-floating>
          <button-floating v-else @click="openReservation">
            {{ $t('openReservation.openReservation') }}
          </button-floating>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isFunction, isNil } from 'lodash/lang';
import scrollIntoView from 'scroll-into-view-if-needed';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import ButtonFloating from '@peda/hm-public-vue-component-library/src/components/ButtonFloating/ButtonFloating';
import HotelContact from '@/components/util/HotelContact';

export default {
  name: 'OpenReservationView',

  components: {
    InputText, ButtonFloating, HotelContact,
  },
  props: {
    msg: String,
  },

  data: function () {
    return {
      confirmationNumber: null,
      pinCode: null,
      invalid: true,
    };
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
  },

  watch: {
    '$v.$invalid': function (newVal) {
      this.invalid = newVal;
    },
  },

  validations() {
    const ret = {
      confirmationNumber: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(8),
      },
      pinCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
    };

    return ret;
  },

  created() {
    this.$store.commit('portal.confirmationNumber', null);
    this.$store.commit('portal.pinCode', null);
    this.$store.commit('portal.reservationGroup', null);
  },

  methods: {
    isNil,
    validate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.setTimeout(() => {
          const invalidElement = this.$el.querySelector('label.invalid');
          if (isFunction(invalidElement.scrollIntoView)) {
            scrollIntoView(
              invalidElement,
              {
                scrollMode: 'if-needed',
                block: 'nearest',
                inline: 'nearest',
                behavior: 'smooth',
              },
            );
          }
        }, 250);
      }
    },
    isInvalid(fieldName) {
      return !isNil(this.$v[fieldName]) && this.$v[fieldName].$dirty && this.$v[fieldName].$invalid;
    },
    openReservation() {
      if (this.invalid) return;
      this.$router.push({
        name: 'portal.reservationGroup',
        params: {
          encodedHotelId: this.$route.params.encodedHotelId,
          confirmationNumber: this.confirmationNumber,
          pinCode: this.pinCode,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
