<template>
  <a
    href="#"
    :class="[{'active': active}, {'complete': complete}]"
    @click="onClick($event)"
  >
    <h3>
      {{ title }}
    </h3>
    <span class="check">
      <check-icon size="16" class="text-primary mt-4"></check-icon>
    </span>
    <template v-if="complete">
      <span class="text-gray text-selected text-12 mt-8">
        <slot name="complete" />
      </span>
    </template>
    <template v-else>
      <span class="text-gray text-12 mt-8">
        <slot />
      </span>
    </template>
  </a>
</template>

<script>

import { CheckIcon } from 'vue-feather-icons';

export default {
  name: 'NavItemRelative',

  components: {
    CheckIcon,
  },

  props: {
    title: {
      type: String,
      default: '-',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    complete: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
  },

  methods: {
    onClick($event) {
      $event.preventDefault();
      this.$serviceBus.$emit('navigation.click');
      this.$emit('click');
    },
  },
};
</script>

<style scoped>

</style>
