<template>
  <div>
    <div class="contact">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('kiosk.headline') }}
        </h1>
        <div class="body text-gray">
          <p class="mb-12">
            Bitte verwenden den Link, welcher in den Kiosk-Einstellungen angezeigt wird.
          </p>
          <p>Please use the link provided in the kiosk settings page.</p>
          <button-floating v-if="!isEmpty(lastHotelId)" @click="openLastHotelId">
            {{ $t('kiosk.reload') }}
          </button-floating>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNil } from 'lodash/lang';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import Loading from '@peda/hm-public-vue-component-library/src/components/Loading';
import ButtonFloating from '@peda/hm-public-vue-component-library/src/components/ButtonFloating/ButtonFloating';

export default {
  name: 'KioskConnectionView',

  components: {
    ButtonFloating,
    Loading,
    InputText,
  },
  props: {
    msg: String,
  },

  data: function () {
    return {
      lastHotelId: null,
    };
  },

  computed: {
  },

  created() {
    this.lastHotelId = this.$store.getters.hotelId;
  },

  beforeDestroy() {
  },

  methods: {
    isEmpty,
    isNil,
    openLastHotelId() {
      this.$router.push({ name: 'kiosk.hotel', params: { encodedHotelId: this.lastHotelId } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
