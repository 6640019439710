<template>
  <navigation
    :step="step"
    :steps="steps"
    :back-button="$route.meta.back || false"
    :logo="hotel.details.logo"
    :name="hotel.hotel.hotelName"
    :white-label-url="hotel.hotel.whiteLabelUrl"
    :white-label-name="hotel.hotel.whiteLabelName"
    :languages="languages"
    @imprint="showImprint"
    @privacy="showPrivacy"
  >
    <nav-item-relative
      :key="'travel'"
      :title="$t('general')"
      :complete="true"
      @click="onClickTravel"
    />
    <template v-if="!isNil(meldeblatt) && !isEmpty(meldeblatt.guests)">
      <template v-for="(guest, idx) in meldeblatt.guests">
        <nav-item-relative
          :key="idx"
          :title="`${$t('guest')} ${idx + 1}`"
          :active="activeRecords.includes(idx)"
          :complete="completeRecords.includes(idx)"
          @click="onClickGuest(idx)"
        >
          <template #complete>
            {{ guest.namePrefix }} {{ guest.firstName }} {{ guest.lastName }} {{ guest.Suffix }}
          </template>
          <template #default>
            {{ $t('completeGuestData') }}
          </template>
        </nav-item-relative>
      </template>
    </template>
    <template v-if="!isNil(meldeblatt)">
      <nav-item-relative
        v-if="meldeblatt.configuration.digitalSignature"
        :key="'signature'"
        :title="$t('signature')"
        :active="activeRecords.includes('signature')"
        :complete="completeRecords.includes('signature')"
        @click="onClickSection('signature')"
      />
    </template>
  </navigation>
</template>

<script>
import Nav from '@peda/hm-public-vue-component-library/src/components/Nav';
import NavItem from '@peda/hm-public-vue-component-library/src/components/NavItem';
import NavMixin from '@/mixins/NavMixin';
import { isEmpty, isNil } from 'lodash/lang';
import NavItemRelative from '@peda/hm-public-vue-component-library/src/components/NavItemRelative';

export default {
  name: 'KioskMeldeblattNav',

  components: {
    'navigation': Nav,
    NavItem,
    NavItemRelative,
  },

  mixins: [NavMixin],

  data() {
    return {
      activeRecords: [],
      completeRecords: [],
    };
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
    meldeblatt() {
      return this.$store.getters['kiosk.meldeblatt'];
    },
    steps() {
      if (!isNil(this.meldeblatt) && !isEmpty(this.meldeblatt.guests)) {
        return 1 + this.meldeblatt.guests.length + (this.meldeblatt.configuration.digitalSignature ? 1 : 0);
      } else {
        return 1;
      }
    },
    step() {
      if (this.activeRecords.includes('signature')) return this.steps;
      return Math.max(...[0, ...((this.activeRecords || []).filter((it) => typeof it === 'number'))]) + 1 + 1;
    },
    languages: function () {
      return this.$store.getters.languages || ['de', 'en'];
    },
  },

  created() {
    this.$serviceBus.$on('kiosk.meldeblatt.activeRecords', this.onActiveRecords);
    this.$serviceBus.$on('kiosk.meldeblatt.completeRecords', this.onCompleteRecords);
  },

  beforeDestroy() {
    this.$serviceBus.$off('kiosk.meldeblatt.activeRecords', this.onActiveRecords);
    this.$serviceBus.$off('kiosk.meldeblatt.completeRecords', this.onCompleteRecords);
  },

  methods: {
    isNil,
    isEmpty,
    onClickGuest(idx) {
      this.$serviceBus.$emit('nav.clickGuest', idx);
    },
    onClickTravel() {
      document.body.scrollIntoView({ behavior: 'smooth' });
    },
    onActiveRecords(activeRecords) {
      this.activeRecords = (activeRecords || []);
    },
    onCompleteRecords(completeRecords) {
      this.completeRecords = (completeRecords || []);
    },
    onClickSection(idx) {
      this.$serviceBus.$emit('nav.clickSection', idx);
    },
  },
};
</script>

<style scoped>

</style>
