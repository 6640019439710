<template>
  <a
    href="#"
    :class="[{'active': active}, {'complete': complete}, {'failed': failed}]"
    @click="onClick($event)"
  >
    <h3>
      {{ title }}
    </h3>
    <span class="check">
      <check-icon v-if="complete" size="16" class="text-primary mt-4"></check-icon>
      <x-icon v-else-if="failed" size="16" class="text-error mt-4"></x-icon>
    </span>
    <template v-if="complete">
      <span class="text-gray text-selected text-12 mt-8">
        <slot name="complete" />
      </span>
    </template>
    <template v-else-if="failed">
      <span class="text-gray text-selected text-12 mt-8">
        <slot name="failed" />
      </span>
    </template>
    <template v-else>
      <span class="text-gray text-12 mt-8">
        <slot />
      </span>
    </template>
  </a>
</template>

<script>

import { CheckIcon, XIcon } from 'vue-feather-icons';
import { trim } from 'lodash/string';

export default {
  name: 'NavItem',

  components: {
    CheckIcon, XIcon,
  },

  props: {
    to: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '-',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: true,
    },
    complete: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    toPath: function () {
      return `/${trim(this.$router.resolve(this.to).href, ' \n\r#/').replace(/\?.*$/, '')}/`;
    },
    currentPath: function () {
      return `/${trim(this.$route.path, ' \n\r#/').replace(/\?.*$/, '')}/`;
    },
    active: function () {
      if (this.exact && this.toPath === this.currentPath) return true;
      if (!this.exact && this.currentPath.indexOf(this.toPath) === 0) return true;
      return false;
    },
  },

  methods: {
    onClick($event) {
      $event.preventDefault();
      if (!this.disabled && this.toPath !== this.currentPath) {
        this.$router.push(this.to);
      }
      this.$serviceBus.$emit('navigation.click');
    },
  },
};
</script>

<style scoped>

</style>
