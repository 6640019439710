<template>
  <div
    :id="$attrs['id']"
    :class="[...($attrs['class'] || []), 'layout']"
    :style="$attrs['style']"
  >
    <loading v-if="loading" />
    <div
      v-else-if="loaded"
      :key="contentKey"
    >
      <div :class="['grid-wrapper', { 'grid-wrapper--wide': wide }]">
        <div class="relative">
          <slot name="nav" />
          <info-box />
          <language-modal :languages="languages" />
        </div>
        <page-transition>
          <slot />
        </page-transition>
        <page-transition>
          <slot name="column-2" />
        </page-transition>
        <slot name="modal" />
      </div>
      <modal
        :showing="!isEmpty(modalUrl)"
        :show-close="true"
        :background-close="true"
        @close="modalUrl = null"
      >
        <iframe class="w-full h-full p-0" :src="modalUrl" title="legal terms" style="height: calc(100% - 5rem);" />
        <div class="w-full absolute bottom-0 py-16 px-24 bg-grayLight cursor-pointer underline text-12 font-semibold" @click="openUrlWindow(modalUrl)">
          {{ $t('modal.openIframeNewWindow') }}
        </div>
      </modal>
      <slot name="additional" />
    </div>
    <div v-else>
      <div :class="['grid-wrapper', { 'grid-wrapper--wide': wide }]">
        <div class="relative">
          <slot name="nav" />
        </div>
        <page-transition>
          <slot />
        </page-transition>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading';
import { FadeTransition } from 'vue2-transitions';
import InfoBox from './InfoBox';
import { isEmpty, isNil } from 'lodash/lang';
import Modal from './Modal/Modal';
import LanguageModal from './Modal/LanguageModal';

export default {
  name: 'Layout',

  components: {
    Loading,
    'info-box': InfoBox,
    'page-transition': FadeTransition,
    Modal,
    LanguageModal,
  },

  props: {
    contentKey: {
      type: String,
      default: '0000',
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    loaded: {
      type: Boolean,
      default: true,
      required: false,
    },
    wide: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      modalUrl: null,
    };
  },

  computed: {
    languages: function () {
      return this.$store.getters.languages || ['de', 'en'];
    },
  },

  created() {
    this.$serviceBus.$on('openUrlModal', this.openUrlModal);
  },

  beforeDestroy() {
    this.$serviceBus.$off('openUrlModal', this.openUrlModal);
  },

  methods: {
    isEmpty,
    isNil,
    openUrlModal(url) {
      this.modalUrl = url;
    },
    openUrlWindow(modalUrl) {
      window.open(modalUrl, '_blank');
      this.modalUrl = null;
    },
  },

  i18n: {
    messages: {
      de: {
        modal: {
          openIframeNewWindow: 'In neuem Fenster öffnen',
        },
      },
      en: {
        modal: {
          openIframeNewWindow: 'Open in new window',
        },
      },
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/main';
</style>

<style lang="scss">
.layout {
  //@apply bg-grayVeryLight min-h-screen;
  @apply bg-grayCol2 min-h-screen;
}

.grid-wrapper {
  @apply grid w-full;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (min-width: 1024px) {
    @apply grid w-full;
    grid-template-columns: 40rem 1fr;
    grid-template-rows: auto;
  }
  @media (min-width: 1280px) {
    @apply grid w-full;
    grid-template-columns: 40rem 70rem 1fr;
    grid-template-rows: auto;

    &--wide {
      grid-template-columns: 40rem 1fr;
    }
  }
}
</style>
