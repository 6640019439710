<template>
  <navigation
    :step="2"
    :steps="6"
    :back-button="$route.meta.back || false"
    :logo="hotel.details.logo"
    :name="hotel.hotel.hotelName"
    :white-label-url="hotel.hotel.whiteLabelUrl"
    :white-label-name="hotel.hotel.whiteLabelName"
    :languages="languages"
    @imprint="showImprint"
    @privacy="showPrivacy"
  >
  </navigation>
</template>

<script>
import Nav from '@peda/hm-public-vue-component-library/src/components/Nav';
import NavItem from '@peda/hm-public-vue-component-library/src/components/NavItem';
import NavMixin from '@/mixins/NavMixin';
import LanguageModalMixin from '@/mixins/LanguageModalMixin';

export default {
  name: 'PaymentRequestNav',

  components: {
    'navigation': Nav,
    NavItem,
  },

  mixins: [NavMixin, LanguageModalMixin],

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
    languages: function () {
      return this.$store.getters.languages || ['de', 'en'];
    },
  },
};
</script>

<style scoped>

</style>
