<template>
  <navigation
    :step="step"
    :steps="steps"
    :back-button="$route.meta.back || false"
    :logo="hotel.details.logo"
    :name="hotel.hotel.hotelName"
    :white-label-url="hotel.hotel.whiteLabelUrl"
    :white-label-name="hotel.hotel.whiteLabelName"
    :languages="languages"
  >
    <nav-item
      :key="1"
      disabled
      :title="$t('openReservation.openReservation')"
      :to="{ name: 'home', params: { encodedHotelId: $route.params.encodedHotelId } }"
      :complete="!isNil(confirmationNumber)"
    >
      <template #complete>
        {{ $t('reservationSelected', [confirmationNumber]) }}
      </template>
      <template #default>
        {{ $t('selectReservation') }}
      </template>
    </nav-item>
    <template v-if="!isNil(resGrp)">
      <nav-item
        :key="2"
        :title="$t('reservationDetails')"
        :to="{
          name: 'portal.reservationGroup',
          params: {
            encodedHotelId: $route.params.encodedHotelId,
            confirmationNumber: $route.params.confirmationNumber,
            pinCode: $route.params.pinCode,
          }
        }"
        :complete="!isNil(resGrp)"
      >
        <template #complete>
          <template v-if="!isNil(resGrp) && !isNil(resGrp.roomReservations)">
            {{ resGrp.roomReservations.length }} {{ $tc('rooms', resGrp.roomReservations.length) }}<template v-if="resGrp.roomReservations.length > 0">
              &nbsp;| {{ formattedDateRange(resGrp.roomReservations[0].arrivalDate, resGrp.roomReservations[0].departureDate) }}
            </template>
          </template>
        </template>
        <template #default>
        </template>
      </nav-item>
      <template v-for="paymentRequest in resGrp.paymentRequests">
        <nav-item
          :key="`payment-${paymentRequest.shortId}`"
          :title="$t('payment')"
          :to="{
            name: 'portal.payment',
            params: {
              encodedHotelId: $route.params.encodedHotelId,
              confirmationNumber: $route.params.confirmationNumber,
              pinCode: $route.params.pinCode,
              paymentRequestShortId: paymentRequest.shortId,
            }
          }"
          :failed="['Cancelled', 'Failed'].includes(paymentRequest.status)"
          :complete="['Complete'].includes(paymentRequest.status)"
        >
          <!--          :complete="resGrp.paymentRequests.filter(it => ['Complete'].includes(it.status)).length === resGrp.paymentRequests.length"-->

          <template #complete>
            {{ paymentRequest.currency || hotel.details.currency }}&nbsp;{{ paymentRequest.amount | toCurrencyDigits }} | {{ $t(`PaymentRequestStatus.${paymentRequest.status}`) }}
          </template>
          <template #failed>
            <template v-if="paymentRequest.expired">
              {{ $t('paymentRequestExpired', [formatDateTime(paymentRequest.expireAt, 'isoDateTime')]) }}<br />
            </template>
            <template v-else>
              {{ paymentRequest.currency || hotel.details.currency }}&nbsp;{{ paymentRequest.amount | toCurrencyDigits }} | {{ $t(`PaymentRequestStatus.${paymentRequest.status}`) }}
            </template>
          </template>
          <template #default>
            <template v-if="resGrp.paymentRequests.filter(it => ['Initial'].includes(it.status)).length > 0">
              {{ $t('paymentRequired') }}
            </template>
            <template v-else-if="resGrp.paymentRequests.filter(it => ['InProgress'].includes(it.status)).length > 0">
              {{ $t('paymentProcessing') }}
            </template>
          </template>
        </nav-item>
      </template>
      <template v-for="(meldeblatt, meldeblattIdx) in resGrp.meldeblaetter">
        <nav-item
          :key="`meldeblatt-${meldeblattIdx}`"
          :title="$t('guestData')"
          :to="{
            name: 'portal.meldeblatt',
            params: {
              encodedHotelId: $route.params.encodedHotelId,
              confirmationNumber: $route.params.confirmationNumber,
              pinCode: $route.params.pinCode,
              encodedMeldeblattId: meldeblatt.id,
              hmac: meldeblatt.hmac,
            }
          }"
          :complete="['Complete', 'CompleteSigned'].includes(meldeblatt.validationStatus) || ['Sent', 'Reservation', 'Departed', 'Deleted'].includes(meldeblatt.status)"
        >
          <template #complete>
            {{ meldeblatt.personCount }} {{ $tc('persons', meldeblatt.personCount) }} | {{ formattedDateRange(meldeblatt.arrivalDate, meldeblatt.departureDate) }}
          </template>
          <template #default>
            {{ $t('enterGuestData') }}
          </template>
        </nav-item>
      </template>
    </template>
  </navigation>
</template>

<script>
import Nav from '@peda/hm-public-vue-component-library/src/components/Nav';
import NavItem from '@peda/hm-public-vue-component-library/src/components/NavItem';
import FormatDateRangeMixin from '@/mixins/FormatDateRangeMixin';
import { isEmpty, isNil } from 'lodash/lang';
import FilterMixin from '@peda/hm-public-vue-component-library/src/mixins/FilterMixin';
import { findIndex } from 'lodash/array';
import LanguageModalMixin from '@/mixins/LanguageModalMixin';

export default {
  name: 'ReservationGroupNav',

  components: {
    'navigation': Nav,
    NavItem,
  },

  mixins: [FormatDateRangeMixin, FilterMixin, LanguageModalMixin],

  data() {
    return {
      confirmationNumber: null,
      pinCode: null,
      resGrp: null,
    };
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
    steps() {
      if (this.$route.name === 'home') return 1;
      else if (isNil(this.resGrp)) return 2;
      else return 2 + this.resGrp.paymentRequests.length + this.resGrp.meldeblaetter.length;
    },
    step() {
      if (this.$route.name === 'home') return 1;
      else if (this.$route.name === 'portal.reservationGroup') return 2;
      else if (this.$route.name === 'portal.payment') {
        const id = this.$route.params.paymentRequestShortId;
        if (isNil(this.resGrp) || isNil(this.resGrp.paymentRequests)) return 1;
        else return 2 + Math.max(1, findIndex(this.resGrp.paymentRequests, ['shortId', id]) + 1);
      } else if (this.$route.name === 'portal.meldeblatt') {
        const id = this.$route.params.encodedMeldeblattId;
        if (isNil(this.resGrp) || isNil(this.resGrp.meldeblaetter)) return 1;
        else return 2 + this.resGrp.paymentRequests.length + Math.max(1, findIndex(this.resGrp.meldeblaetter, ['id', id]) + 1);
      } else {
        return 1;
      }
    },
  },

  watch: {
    '$route.params.confirmationNumber': function (newVal) {
      this.$store.commit('portal.confirmationNumber', newVal);
      this.reloadData();
    },
    '$route.params.pinCode': function (newVal) {
      this.$store.commit('portal.pinCode', newVal);
      this.reloadData();
    },
  },

  created() {
    this.confirmationNumber = this.$route.params.confirmationNumber;
    this.pinCode = this.$route.params.pinCode;
    this.$store.commit('portal.confirmationNumber', this.$route.params.confirmationNumber);
    this.$store.commit('portal.pinCode', this.$route.params.pinCode);
    this.$serviceBus.$on('meldeblatt.update', this.reloadData);
    this.$serviceBus.$on('meldeblatt.open', this.reloadData);
    this.loadData();
  },

  beforeDestroy() {
    this.$serviceBus.$off('meldeblatt.update', this.reloadData);
    this.$serviceBus.$off('meldeblatt.open', this.reloadData);
  },

  methods: {
    isNil,
    loadData() {
      if (!isEmpty(this.confirmationNumber) && !isEmpty(this.pinCode)) {
        if (this.confirmationNumber === this.$store.getters['portal.confirmationNumber']
            && this.pinCode === this.$store.getters['portal.pinCode']) {
          this.resGrp = this.$store.getters['portal.reservationGroup'];
        } else {
          this.resGrp = null;
        }

        if (isNil(this.resGrp)) {
          this.reloadData();
        }
      }
    },
    reloadData() {
      this.$superagent
        .get(this.$serviceUrls.reservationGroup.get(this.$route.params.encodedHotelId, this.confirmationNumber, this.pinCode))
        .then((res) => {
          this.resGrp = res.body.data;
          this.$store.commit('portal.reservationGroup', res.body.data);
        })
        .catch((err) => {
          this.$router.push({
            name: 'home',
            params: {
              encodedHotelId: this.$route.params.encodedHotelId,
            },
            query: {
              error: 1,
            },
          });
        });
    },
  },
};
</script>

<style scoped>

.inline-block {
  display: inline-block!important;
}

</style>
