<template>
  <div>
    <div v-if="!isNil(paymentRequest)" class="paymentRequest">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('paymentRequest.headline') }}
        </h1>
        <table class="w-full table">
          <template v-for="(folioPaymentDetail, idx) in paymentRequest.folioPaymentDetails">
            <tr>
              <td class="pb-16" :class="[{ 'pt-16 border-t border-grayLight': (idx > 0)}]">
                <div><span class="font-semibold">{{ $t('paymentRequest.folioPaymentDetail.reservation') }} #{{ folioPaymentDetail.parentObject.reservationNumber }} // {{ folioPaymentDetail.parentObject.displayName || '-' }}</span></div>
                <div><span class="text-gray text-14">{{ formatDate(folioPaymentDetail.parentObject.arrivalDate, 'YYYYMMDD') }} - {{ formatDate(folioPaymentDetail.parentObject.departureDate, 'YYYYMMDD') }} // {{ folioPaymentDetail.parentObject.numAdults }} {{ $t('paymentRequest.folioPaymentDetail.adults') }}<template v-if="folioPaymentDetail.parentObject.numChildren > 0"> / {{ folioPaymentDetail.parentObject.numChildren }} {{ $t('paymentRequest.folioPaymentDetail.children') }}</template></span></div>
                <div><span class="text-gray text-14">{{ getRoomTypeName(folioPaymentDetail.parentObject.roomTypeId) }} // {{ getRatePlanName(folioPaymentDetail.parentObject.ratePlanId) }}</span></div>
              </td>
              <td :class="[{ 'pt-16 border-t border-grayLight': (idx > 0)}]">
                <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">
                  {{ folioPaymentDetail.currency }} {{ folioPaymentDetail.amount | toCurrencyDigits }}
                </span>
              </td>
            </tr>
          </template>
          <tr>
            <td class="border-t-2 border-grayLight pb-16" colspan="3">
            </td>
          </tr>
          <tr>
            <td>
              <div><span class="text-16 font-semibold pr-16">{{ $t('paymentRequest.folioPaymentDetail.paymentAmount') }}</span></div>
              <!--              <div v-if="paymentRequest.status !== 'Complete'">-->
              <!--                <span class="text-gray text-14">{{ $t('paymentRequest.folioPaymentDetail.dueUntil', [formatDate(paymentRequest.dueDate, 'YYYYMMDD')]) }}</span>-->
              <!--              </div>-->
            </td>
            <td>
              <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">{{ paymentRequest.currency }}&nbsp;{{ paymentRequest.amount | toCurrencyDigits }}</span>
            </td>
          </tr>
        </table>
        <div class="package border-1.5 border-grayLight rounded-8 p-16 mt-32">
          <div v-if="paymentRequest.status === 'Complete'" class="body text-success grid grid-cols-paymentStatus">
            <span><check-icon size="48"></check-icon></span>
            <span class="pl-16 self-center text-24">{{ $t('paymentRequest.paymentStatus.Complete') }}</span>
          </div>
          <div v-else-if="paymentRequest.status === 'Initial'" class="body text-gray">
            {{ $t('paymentRequest.paymentStatus.Due', [formatDateTime(paymentRequest.expireAt, 'isoDateTime')]) }}
            <template v-if="!isEmpty(paymentRequest.additionalInformation)">
              <template v-if="!isEmpty(paymentRequest.additionalInformation[locale])">
                <div class="pt-16" v-html="paymentRequest.additionalInformation[locale]">
                </div>
              </template>
              <template v-else-if="!isEmpty(paymentRequest.additionalInformation[hotel.details.primaryLanguage])">
                <div class="pt-16" v-html="paymentRequest.additionalInformation[hotel.details.primaryLanguage]">
                </div>
              </template>
            </template>
          </div>
          <div v-else-if="paymentRequest.status === 'InProgress'" class="body text-grayBlue grid grid-cols-paymentStatus">
            <span><refresh-cw-icon size="38" class="animate-spin-slow"></refresh-cw-icon></span>
            <span class="pl-16 self-center text-24">{{ $t('paymentRequest.paymentStatus.InProgress') }}</span>
            <span class="pt-16 text-14 text-gray leading-none col-span-2">{{ $t('paymentRequest.paymentStatus.InProgress_desc') }}</span>
          </div>
          <div v-else class="body text-error grid grid-cols-paymentStatus">
            <span><x-icon size="48"></x-icon></span>
            <span class="pl-16 self-center text-24">{{ $t('paymentRequest.paymentStatus.Failed') }}</span>
          </div>
        </div>
        <hotel-contact />

        <button-floating v-if="paymentRequest.status === 'Initial' && !['Manual'].includes(paymentRequest.psp)" @click="onClickPayNow">
          {{ $t('paymentRequest.button.payNow', [`${paymentRequest.currency}&nbsp;${$options.filters.toCurrencyDigits(paymentRequest.amount)}`]) }}
        </button-floating>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNil } from 'lodash/lang';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import FilterMixin from '@peda/hm-public-vue-component-library/src/mixins/FilterMixin';
import LocaleMixin from '@/mixins/LocaleMixin';
import ButtonFloating from '@peda/hm-public-vue-component-library/src/components/ButtonFloating/ButtonFloating';
import { CheckIcon, XIcon, RefreshCwIcon } from 'vue-feather-icons';
import HotelContact from '@/components/util/HotelContact';

export default {
  name: 'PaymentRequestView',

  components: {
    HotelContact,
    InputText,
    ButtonFloating,
    CheckIcon,
    XIcon,
    RefreshCwIcon,
  },

  mixins: [FilterMixin, LocaleMixin],
  props: {
    msg: String,
  },

  data: function () {
    return {
      paymentRequestShortId: null,
      paymentRequest: null,
    };
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
  },

  created() {
    this.paymentRequestShortId = this.$route.params.paymentRequestShortId;
    this.loadData();
  },

  methods: {
    isEmpty,
    loadData() {
      if (!isEmpty(this.paymentRequestShortId)) {
        this.$superagent
          .get(this.$serviceUrls.paymentRequest.get(this.$route.params.encodedHotelId, this.paymentRequestShortId))
          .then((res) => {
            this.paymentRequest = res.body.data;
          })
          .catch((err) => {
            this.$rollbar.error(err);
          });
      }
    },
    onClickPayNow() {
      window.location = this.paymentRequest.pspUrl;
    },
    isNil,
    getRoomTypeName(roomTypeId) {
      const roomType = this.hotel.roomTypes.find((it) => it.id === roomTypeId);
      return (isNil(roomType) ? '-' : roomType.name[this.locale]) || '-';
    },
    getRatePlanName(ratePlanId) {
      const ratePlan = this.hotel.ratePlans.find((it) => it.id === ratePlanId);
      return (isNil(ratePlan) ? '-' : ratePlan.name[this.locale]) || '-';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
