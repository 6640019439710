/* eslint-disable global-require */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VTooltip from 'v-tooltip';
import VueHotkey from 'v-hotkey';
import Vuex from 'vuex';

import '@/assets/css/style.css';
import baseUrl from './lib/urls';
import prefix from 'superagent-prefix';
import Vuelidate from 'vuelidate';

import VueI18n, { BaseFormatter } from 'vue-i18n';
import { isEmpty, isNil } from 'lodash/lang';
import { replace } from 'lodash/string';
import Rollbar from 'vue-rollbar';

import VueHead from 'vue-head';
import VueGtag from 'vue-gtag';
import ServiceUrls from '@/lib/ServiceUrls';
import VueSignaturePad from 'vue-signature-pad';
import inViewportDirective from 'vue-in-viewport-directive';

import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(Rollbar, {
  accessToken: '93c846016abb4644a8878bf9a88135ca',
  captureUncaught: true,
  autoInstrument: { network: true, log: true, dom: true, navigation: true, connectivity: true },
  captureUnhandledRejections: true,
  enabled: process.env.VUE_APP_HOTELMEISTER_ENV !== 'development',
  environment: process.env.VUE_APP_HOTELMEISTER_ENV,
  captureIp: 'anonymize',
  hostBlackList: ['localhost'],
  payload: {
    environment: process.env.VUE_APP_HOTELMEISTER_ENV,
    client: {
      javascript: {
        code_version: process.env.VUE_APP_VERSION,
      },
    },
  },
  ignoredMessages: [
    'Failed to update a ServiceWorker for scope',
    'Request failed with status code 401',
    'UNKNOWN for http[s]?://[^/]+/rest/ui/backend/echo/keepAlive',
    'NavigationDuplicated',
    'Navigation cancelled from',
    'Avoided redundant navigation to',
    'Script error.',
    'Cannot read properties of null (reading \'contains\')',
  ],
});

Vue.use(VueSweetalert2);

Vue.directive('in-viewport', inViewportDirective);
Vue.use(VueHead);
Vue.use(VueSignaturePad);

const countries = require('i18n-iso-countries');

countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'));

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackRoot: true,
  fallbackLocale: ['en', 'de'],
  silentTranslationWarn: true,
  messages: {
    de: {
      ...require('@/i18n/de.json'),
    },
    en: {
      ...require('@/i18n/en.json'),
    },
    el: {
      ...require('@/i18n/el.json'),
    },
    es: {
      ...require('@/i18n/es.json'),
    },
    fr: {
      ...require('@/i18n/fr.json'),
    },
    it: {
      ...require('@/i18n/it.json'),
    },
    nl: {
      ...require('@/i18n/nl.json'),
    },
    pl: {
      ...require('@/i18n/pl.json'),
    },
    pt: {
      ...require('@/i18n/pt.json'),
    },
    ru: {
      ...require('@/i18n/ru.json'),
    },
  },
  missing: (locale, key, vm, values) => {
    if (!isNil(vm)) {
      let parent = vm;
      // eslint-disable-next-line no-cond-assign
      while (!isNil(parent = parent.$parent)) {
        if (!isNil(parent.$options) && !isNil(parent.$options.i18n) && !isNil(parent.$options.i18n.messages) && !isNil(parent.$options.i18n.messages[locale])) {
          let msg = parent.$options.i18n.messages[locale];
          const splitKey = key.split('.');
          // eslint-disable-next-line no-restricted-syntax
          for (const it of splitKey) {
            msg = msg[it];
            if (isNil(msg)) break;
          }
          if (!isEmpty(msg)) {
            const localMsg = {};
            let last = localMsg;
            for (let i = 0; i < splitKey.length; i++) {
              const it = splitKey[i];
              if ((i + 1) < splitKey.length) {
                last[it] = {};
              } else {
                last[it] = msg;
              }
              last = localMsg[it];
            }
            vm._i18n.mergeLocaleMessage(locale, localMsg);
            return vm.$t(key);
          }
        }
      }
    }
    if (key.includes('UNIT_TYPE')) {
      const hotel = isNil(vm) ? null : vm.$root.$store.getters.hotel;
      let wordingType = 'Room';
      if (!isEmpty(hotel) && !isEmpty(hotel.details) && !isEmpty(hotel.details.onlineBookingSettings) && !isEmpty(hotel.details.onlineBookingSettings.wordingType)) {
        wordingType = hotel.details.onlineBookingSettings.wordingType;
      }
      key = replace(key, 'UNIT_TYPE', wordingType);

      return isNil(vm) ? key : vm.$t(key);
    }
    return key;
  },
});

Vue.use(Vuelidate);

const superagent = require('superagent-use')(require('superagent'));

Vue.config.productionTip = false;

superagent.use(prefix(baseUrl));

const serviceBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $countries: {
    get: function get() {
      return countries;
    },
  },
  $superagent: {
    get: function get() {
      return superagent;
    },
  },
  $serviceBus: {
    get: function get() {
      return serviceBus;
    },
  },
  $serviceUrls: {
    get: function get() {
      return ServiceUrls;
    },
  },
});

const tooltipOptions = {
  // Default tooltip placement relative to target element
  defaultPlacement: 'top',
  // Default CSS classes applied to the tooltip element
  defaultClass: 'vue-tooltip-theme',
  // Default CSS classes applied to the target element of the tooltip
  defaultTargetClass: 'has-tooltip',
  // Is the content HTML by default?
  defaultHtml: true,
  // Default HTML template of the tooltip element
  // It must include `tooltip-arrow` & `tooltip-inner` CSS classes (can be configured, see below)
  // Change if the classes conflict with other libraries (for example bootstrap)
  defaultTemplate:
    '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
  // Selector used to get the arrow element in the tooltip template
  defaultArrowSelector: '.tooltip-arrow, .tooltip__arrow',
  // Selector used to get the inner content element in the tooltip template
  defaultInnerSelector: '.tooltip-inner, .tooltip__inner',
  // Delay (ms)
  defaultDelay: 0,
  // Default events that trigger the tooltip
  defaultTrigger: 'hover focus click',
  // Default position offset (px)
  defaultOffset: 0,
  // Default container where the tooltip will be appended
  defaultContainer: 'body',
  defaultBoundariesElement: undefined,
  defaultPopperOptions: {},
  // Class added when content is loading
  defaultLoadingClass: 'tooltip-loading',
  // Displayed when tooltip content is loading
  defaultLoadingContent: '...',
  // Hide on mouseover tooltip
  autoHide: true,
  // Close tooltip on click on tooltip target?
  defaultHideOnTargetClick: true,
  // Auto destroy tooltip DOM nodes (ms)
  disposeTimeout: 5000,
  // Options for popover
  popover: {
    defaultPlacement: 'bottom',
    // Use the `popoverClass` prop for theming
    defaultClass: 'vue-popover-theme',
    // Base class (change if conflicts with other libraries)
    defaultBaseClass: 'tooltip popover',
    // Wrapper class (contains arrow and inner)
    defaultWrapperClass: 'wrapper',
    // Inner content class
    defaultInnerClass: 'tooltip-inner popover-inner',
    // Arrow class
    defaultArrowClass: 'tooltip-arrow popover-arrow',
    // Class added when popover is open
    defaultOpenClass: 'open',
    defaultDelay: 0,
    defaultTrigger: 'click',
    defaultOffset: 0,
    defaultContainer: 'body',
    defaultBoundariesElement: undefined,
    defaultPopperOptions: {},
    // Hides if clicked outside of popover
    defaultAutoHide: true,
    // Update popper on content resize
    defaultHandleResize: true,
  },
};

Vue.use(VTooltip, tooltipOptions);
Vue.use(VueHotkey, {
  ß: 219,
  '/': 191,
  n0: 48,
  n1: 49,
  n2: 50,
  n3: 51,
  n4: 52,
  n5: 53,
  n6: 54,
  n7: 55,
  n8: 56,
  n9: 57,
});

Vue.use(Vuex);

Vue.use(VueGtag, {
  bootstrap: false,
  appName: 'HotelVueApp',
}, router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
