<template>
  <div class="mb-24">
    <label
      class="input-label text-14 text-dark font-medium mb-8 block"
      :class="[ {'invalid': invalid }]"
      :for="name"
    >
      {{ label }} <span class="text-gray">{{ optional ? '(optional)' : '' }}</span>
    </label>
    <input
      :id="name"
      v-model="internalValue"
      :type="type"
      :name="name"
      :placeholder="disabled ? '' : placeholder"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      class="input focus:border-primary active:border-primary appearance-none focus:outline-none"
      :class="[ {'invalid': invalid }]"
      :disabled="disabled"
      @blur="$emit('blur', $event)"
    >
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import InputMixin from '../../../mixins/InputMixin';
import ErrorMessage from '../ErrorMessage';

export default {
  components: { ErrorMessage },
  mixins: [InputMixin],
  props: {
    value: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      internalValue: null,
    };
  },

  computed: {},

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
  },

  created: function () {
    this.internalValue = this.value;
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
