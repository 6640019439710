// source: https://stackoverflow.com/questions/51425067/flatpickr-adding-dropdown-to-change-year

// plugin js file
/**
 * Flatpickr Year Select Plugin.
 * @author Labi Romabravo
 */

/**
 *
 * @returns {Function}
 */
const yearDropdownPlugin = function (pluginConfig) {
  const defaultConfig = {
    text: '',
    theme: 'light',
    yearStart: 100,
    yearEnd: 2,
    invalid: false,
    disabled: false,
  };

  const config = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in defaultConfig) {
    config[key] = pluginConfig && pluginConfig[key] !== undefined ? pluginConfig[key] : defaultConfig[key];
  }

  const currYear = new Date().getFullYear();

  const yearDropdown = document.createElement('select');

  const start = new Date().getFullYear() - config.yearStart;
  const end = currYear + config.yearEnd;

  for (let i = end; i >= start; i--) {
    const option = document.createElement('option');
    option.value = i;
    option.text = i;
    yearDropdown.appendChild(option);
  }

  const updatedSelectedYear = function (year = new Date().getFullYear()) {
    yearDropdown.value = `${year}`;
  };

  updatedSelectedYear();

  return function (fp) {
    fp.isMobile = window.matchMedia('(pointer: coarse)').matches && !fp.config.disableMobile;

    if (fp.isMobile) {
      fp.element.classList.add('input');
      return { };
    }

    fp.yearSelectContainer = fp._createElement(
      'div',
      `flatpickr-year-select ${config.theme}Theme`,
      config.text,
    );

    function updateYears(args) {
      let currentYear = new Date().getFullYear();
      if (fp.selectedDates && fp.selectedDates.length > 0) {
        currentYear = fp.selectedDates[0].getFullYear();
      }
      updatedSelectedYear(fp.currentYear || currentYear);
    }

    fp.yearSelectContainer.tabIndex = -1;
    updateYears();
    fp.yearElements = [yearDropdown];
    yearDropdown.addEventListener('change', (evt) => {
      const year = evt.target.options[evt.target.selectedIndex].value;
      fp.changeYear(year);
    });

    fp.yearSelectContainer.append(yearDropdown);

    return {
      onValueUpdate: updateYears,
      onMonthChange: updateYears,
      onYearChange: updateYears,
      onReady: [
        updateYears,
        () => {
          const name = fp.monthNav.className;
          const yearInputCollection = fp.calendarContainer.getElementsByClassName(name);
          const el = yearInputCollection[0];
          el.parentNode.insertBefore(fp.yearSelectContainer, el.parentNode.firstChild);
        },
      ],
    };
  };
};

export default yearDropdownPlugin;
// if (typeof module !== "undefined")
//     module.exports = yearDropdownPlugin;
