import ServiceUrls from '@/lib/ServiceUrls';
import baseUrl from '@/lib/urls';
import LocaleMixin from '@/mixins/LocaleMixin';

export default {
  mixins: [LocaleMixin],

  methods: {
    showImprint() {
      this.$serviceBus.$emit('openUrlModal', `${baseUrl}${ServiceUrls.hotel.imprint(this.$route.params.encodedHotelId, this.locale)}`);
    },
    showPrivacy() {
      this.$serviceBus.$emit('openUrlModal', `${baseUrl}${ServiceUrls.hotel.privacy(this.$route.params.encodedHotelId, this.locale)}`);
    },
  },
};
