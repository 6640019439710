<template>
  <div class="floating-button" :class="[{'floating-button--wide': wide}]" :disabled="disabled" @click="onClick">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'ButtonFloating',
  components: { },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {

    };
  },

  computed: {

  },

  watch: {

  },

  created: function () {

  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    onClick($event) {
      this.$emit('click', $event);
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';
  .floating-button {
    @apply fixed bottom-16 left-16 h-64 w-full flex items-center justify-center px-30 py-22 bg-primary z-50 text-white rounded-32 font-semibold cursor-pointer;
    max-width: calc(100% - 3.2rem);

    @media (min-width: 1024px) {
      @apply fixed left-460;
      width: calc(100% - 460px - 64px);
      max-width: 57.2rem;

      &--wide {
        max-width: calc(57.2rem * 2 + 64px * 2);
      }
    }

    &[disabled] {
      @apply bg-grayBlue cursor-not-allowed;
    }
  }
</style>
