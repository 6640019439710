<template>
  <div class="lg:fixed left-0 top-0 lg:w-400 bg-white lg:h-screen overflow-y-auto">
    <nav class="relative grid lg:block justify-between items-center h-64 lg:min-h-screen px-16 lg:px-48 lg:pt-128 lg:pb-128 lg:h-auto">
      <button class="p-8 lg:hidden" :class="[{'pointer-events-none opacity-0': backButton === false}]" @click="onClickBack">
        <chevron-left-icon class="text-primary"></chevron-left-icon>
      </button>
      <div v-if="!isEmpty(logo)" class="logo lg:mb-48 hidden lg:block" @click="onClickLogo">
        <img :src="logo" :alt="$t('logoAltText')" class="logo">
      </div>
      <div class="lg:mb-48 truncate" :class="[{'block lg:hidden': !isEmpty(logo)}]" @click="onClickLogo">
        <span class="text-18 lg:text-24 text-black font-bold uppercase whitespace-no-wrap lg:whitespace-normal">{{ name }}</span>
      </div>
      <button v-if="mobileMenu" class="p-8 lg:hidden" @click="toggleMobileMenu">
        <x-icon class="text-primary"></x-icon>
      </button>
      <div v-else class="dropdown lg:hidden" @click="toggleMobileMenu">
        {{ step }}/{{ steps }}
      </div>
      <div ref="nav" class="list nav-list-wrapper lg:-mx-48 lg:px-48" :class="[{ 'block': mobileMenu }]">
        <ul class="nav-list">
          <li>
            <fade-transition group>
              <slot />
            </fade-transition>
          </li>
        </ul>

        <div class="nav-footer-links">
          <a
            v-if="poweredBy"
            :href="whiteLabelUrl"
            target="_blank"
            rel="noopener"
            class="col-span-1"
          >{{ $t('poweredBy') }} <span class="text-black font-bold">{{ whiteLabelName }}</span></a>
          <div class="nav-footer-links-legal">
            <a href="#" class="font-bold" @click="showImprint">{{ $t('imprint') }}</a>
            <span class="font-bold">·</span>
            <a href="#" class="font-bold" @click="showPrivacy">{{ $t('privacy') }}</a>
            <template v-if="consent != null">
              <span class="font-bold">·</span>
              <a href="#" class="font-bold" @click="showCookieconsent">{{ $t('cookie', 'Cookies') }}</a>
            </template>
          </div>
          <div class="nav-footer-links-legal">
            <select v-model="$root.$i18n.locale" type="select" class="language-switcher col-span-1">
              <option v-if="isEmpty(languages) || languages.includes('de')" value="de">
                Deutsch
              </option>
              <option v-if="isEmpty(languages) || languages.includes('en')" value="en">
                English
              </option>
              <option v-if="isEmpty(languages) || languages.includes('es')" value="es">
                Español
              </option>
              <option v-if="isEmpty(languages) || languages.includes('fr')" value="fr">
                Français
              </option>
              <option v-if="isEmpty(languages) || languages.includes('it')" value="it">
                Italiano
              </option>
              <option v-if="isEmpty(languages) || languages.includes('nl')" value="nl">
                Nederlands
              </option>
              <option v-if="isEmpty(languages) || languages.includes('pl')" value="pl">
                Polski
              </option>
              <option v-if="isEmpty(languages) || languages.includes('pt')" value="pt">
                Português
              </option>
              <option v-if="isEmpty(languages) || languages.includes('ru')" value="ru">
                Русский язык
              </option>
              <option v-if="isEmpty(languages) || languages.includes('el')" value="el">
                Ελληνικά
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 left-0 h-2 bg-primary w-step/6 lg:hidden" :style="`--step: ${step};`"></div>
    </nav>
  </div>
</template>

<script>

import { ChevronLeftIcon, XIcon } from 'vue-feather-icons';
import { isEmpty } from 'lodash/lang';
import { FadeTransition } from 'vue2-transitions';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CookieMixin from '../mixins/CookieMixin';
import TimerMixin from '../mixins/TimerMixin';

export default {
  name: 'Nav',
  components: { ChevronLeftIcon, XIcon, FadeTransition },

  mixins: [CookieMixin, TimerMixin],

  props: {
    logo: {
      default: null,
      required: false,
    },
    name: {
      default: null,
      required: false,
    },
    step: {
      default: 1,
      required: true,
    },
    steps: {
      default: 1,
      required: true,
    },
    whiteLabelUrl: {
      default: 'https://www.hotelmeister.com',
      required: false,
    },
    whiteLabelName: {
      default: 'Hotelmeister',
      required: false,
    },
    backButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    poweredBy: {
      type: Boolean,
      default: true,
      required: false,
    },
    languages: {
      type: Array,
      default: () => ['de', 'en'],
      required: false,
    },
  },

  data: function () {
    return {
      mobileMenu: false,
    };
  },

  computed: {
  },

  watch: {

  },

  created: function () {
    this.$serviceBus.$on('navigation.click', this.navigateClick);

    window.addEventListener('resize', this.hideNavigation);
  },

  beforeDestroy() {
    this.$serviceBus.$off('navigation.click', this.navigateClick);

    window.removeEventListener('resize', this.hideNavigation);
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    isEmpty,
    onClickBack() {
      this.$serviceBus.$emit('back');
      this.$serviceBus.$emit('navigation.click');
    },
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;

      if (this.mobileMenu) {
        disableBodyScroll(this.$refs.nav);
      } else {
        enableBodyScroll(this.$refs.nav);
      }
    },
    navigateClick() {
      this.hideNavigation();
    },
    hideNavigation() {
      if (this.mobileMenu) {
        this.toggleMobileMenu();
      }
    },
    showImprint() {
      this.$emit('imprint');
    },
    showPrivacy() {
      this.$emit('privacy');
    },
    onClickLogo() {
      if (!isEmpty(this.$route.meta) && this.$route.meta.kiosk === true) {
        this.$router.push({ name: 'kiosk.hotel', params: { encodedHotelId: this.$route.params.encodedHotelId } });
      } else {
        this.$router.push({ name: 'home', params: { encodedHotelId: this.$route.params.encodedHotelId } });
      }
    },
  },

  i18n: {
    messages: {
      de: {
        poweredBy: 'powered by',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        cookie: 'Cookies',
        logoAltText: 'Logo',
      },
      en: {
        poweredBy: 'powered by',
        imprint: 'Imprint',
        privacy: 'Privacy',
        cookie: 'Cookies',
        logoAltText: 'Logo',
      },
      'es': {
        'imprint': 'Pie de imprenta',
        'privacy': 'Política de privacidad',
        'cookie': 'Cookies',
        'logoAltText': 'Logotipo',
        'poweredBy': 'con la ayuda de',
      },
      'fr': {
        'imprint': 'Impression',
        'privacy': 'Politique de confidentialité',
        'cookie': 'Cookies',
        'logoAltText': 'Logo',
        'poweredBy': 'alimenté par',
      },
      'it': {
        'imprint': 'Impronta',
        'privacy': 'Politica sulla privacy',
        'cookie': 'Cookie',
        'logoAltText': 'Logo',
        'poweredBy': 'alimentato da',
      },
      'nl': {
        'imprint': 'Afdruk',
        'privacy': 'Privacybeleid',
        'cookie': 'Cookies',
        'logoAltText': 'Logo',
        'poweredBy': 'mogelijk gemaakt door',
      },
      'pl': {
        'imprint': 'Nadruk',
        'privacy': 'Polityka prywatności',
        'cookie': 'Cookies',
        'logoAltText': 'Logo',
        'poweredBy': 'napędzany przez',
      },
      'pt': {
        'imprint': 'Impresso',
        'privacy': 'Política de privacidade',
        'cookie': 'Cookies',
        'logoAltText': 'Logótipo',
        'poweredBy': 'movido a',
      },
      'ru': {
        'imprint': 'Оттиск',
        'privacy': 'Политика конфиденциальности',
        'cookie': 'Cookies',
        'logoAltText': 'Логотип',
        'poweredBy': 'приводимый в действие',
      },
      'el': {
        'poweredBy': 'τροφοδοτείται από',
        'imprint': 'Εκτύπωση',
        'privacy': 'Απόρρητο',
        'cookie': 'Cookies',
        'logoAltText': 'Λογότυπο',
      },
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .logo {
    max-height: 8.5rem;
    height: auto;
    width: auto;
    max-width: 100%;
  }

  nav {
    grid-template-columns: min-content auto min-content;
    grid-column-gap: 1rem;
  }

  .nav-list {
    li {
      a {
        @apply relative -ml-24 pl-24 justify-center py-16;
        //@apply flex flex-col;
        @apply grid;
        grid-template-columns: 1fr min-content;
        grid-auto-flow: row;
        grid-auto-rows: min-content;

        @media (min-width: 1024px) {
          @apply -ml-48 pl-48;
        }

        span {
          @apply hidden;
        }

        &[disabled] {
          @apply pointer-events-none;
        }

        h3 {
          @apply text-16 text-gray font-semibold;
        }

        .check {
          opacity: 0;
        }

        &.complete {
          h3 {
            @apply text-primary;
          }

          .check {
            opacity: 1;
          }

          span {
            @apply block;
          }
        }

        &.failed {
          h3 {
            @apply text-error;
          }

          .check {
            opacity: 1;
          }

          span {
            @apply block;
          }
        }

        &.active {
          h3 {
            @apply text-16 text-dark font-semibold;
          }

          @apply pl-36;

          @media (min-width: 1024px) {
            @apply pl-56;
          }

          .text-selected {
            @apply text-dark;
          }

          span {
            @apply block;
            &:not(.text-gray) {
              @apply text-dark;
            }
          }

          &:before {
            content: '';
            @apply w-4 h-full absolute left-0 top-0 bg-primary rounded-2;
          }
        }
      }
    }
  }

  .nav-footer-links {
    @apply grid bottom-0 left-24 right-24 pt-24 mt-24 -mx-24 px-24 overflow-hidden text-12 text-gray border-t-1 border-grayLight;

    @media (min-width: 1024px) {
      @apply absolute pb-24;
    }

    line-height: 1.6rem;

    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 0.8rem;

    &-legal {
      @apply grid;
      grid-column-gap: 0.5rem;
      grid-template-columns: repeat(5, min-content);
    }

    a {
      @apply whitespace-no-wrap;
      &:hover {
        @apply underline;
      }
    }
  }

  .language-switcher {
    @apply relative -left-4 -top-1 bg-transparent border-0 text-12 px-0 text-gray cursor-pointer font-bold;

    width: 100%;
    width: min-content;

    &--medium {
      @apply text-14 p-0 border-grayLight
    }

    &--large {
      @apply text-18 border-1 p-8 border-grayLight
    }
  }

  .nav-list-wrapper {
    @apply hidden;

    grid-template-rows: 1fr max-content;

    @apply overflow-auto;

    @media (max-width: 1023px) {
      &.block {
        @apply top-64 fixed bg-white bottom-0 left-0 right-0 z-80 px-24 pb-24 pt-8 grid;
      }
    }

    @media (min-width: 1024px) {
      @apply grid;
    }
  }
</style>
