<template>
  <div class="mb-24">
    <label
      class="input-label text-14 text-dark font-medium mb-8 block"
      :for="name"
      :class="[ {'invalid': invalid }]"
    >
      {{ label }} <span class="text-gray">{{ optional ? "(optional)" : "" }}</span>
    </label>
    <div class="grid-cols-12 mt-16 mb-16 p-0 relative input focus:border-primary active:border-primary appearance-none focus:outline-none bg-white" :class="[ {'invalid': invalid }]">
      <div v-if="!disabled" class="text-primary hover:text-dark transition-colors duration-300 absolute right-44 top-16 cursor-pointer select-none" title="Rückgängig">
        <rotate-ccw-icon size="20" @click="onUndo"></rotate-ccw-icon>
      </div>
      <div class="text-primary hover:text-dark transition-colors duration-300 absolute right-16 top-16 cursor-pointer select-none" title="Löschen">
        <x-icon size="20" @click="onClear"></x-icon>
      </div>
      <div v-if="signed" class="text-primary absolute text-14 left-16 right-80 top-16 pointer-events-none select-none">
        &nbsp;
      </div>
      <div v-else-if="disabled" class="text-primary absolute text-14 left-16 right-80 top-16 pointer-events-none select-none">
        {{ $t('disabled') }}
      </div>
      <div v-else-if="mouseAttached" class="text-primary absolute text-14 left-16 right-80 top-16 pointer-events-none select-none">
        {{ $t('clickToSign') }}
      </div>
      <div v-else class="text-primary absolute text-14 left-16 right-80 top-16 pointer-events-none select-none">
        {{ $t('touchToSign') }}
      </div>
      <div v-if="!isEmpty(name)" class="text-dark border-t-1 pt-6 text-14 absolute bottom-16 left-16 right-16 pointer-events-none truncate select-none">
        {{ name }}
      </div>
      <img
        v-if="signed"
        :src="internalValue"
        width="100%"
        height="180px"
        style="margin-top:48px;border-top:1px solid #c8d6e0;margin-bottom:36px;padding:16px"
      />
      <vue-signature-pad
        v-else
        :key="`disabled-${disabled}`"
        ref="signaturePad"
        :disabled="disabled"
        width="100%"
        height="180px"
        :options="signaturePadOptions"
        :custom-style="{ marginTop: '48px', borderTop: '1px solid #c8d6e0' }"
      ></vue-signature-pad>
    </div>
    <canvas
      height="180px"
      class="trimImage"
      style="background: white;display:block;position:fixed;opacity:0;pointer-events: none;"
    />
    <canvas
      height="180px"
      class="dateImage"
      style="background: white;display:block;position:fixed;opacity:0;pointer-events: none;"
    />
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import ErrorMessage from '@peda/hm-public-vue-component-library/src/components/InputElements/ErrorMessage';
import InputMixin from '@peda/hm-public-vue-component-library/src/mixins/InputMixin';
import { XIcon, RotateCcwIcon } from 'vue-feather-icons';
import TimerMixin from '@peda/hm-public-vue-component-library/src/mixins/TimerMixin';
import { isEmpty, isNil } from 'lodash/lang';
import FilterMixin from '@peda/hm-public-vue-component-library/src/mixins/FilterMixin';
import fecha from 'fecha';
import LocaleMixin from '@/mixins/LocaleMixin';

export default {
  components: {
    ErrorMessage,
    XIcon,
    RotateCcwIcon,
  },

  mixins: [InputMixin, TimerMixin, FilterMixin, LocaleMixin],
  props: {
    value: String,
    placeholder: String,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      internalValue: null,
      mouseAttached: false,
      signed: false,
    };
  },

  computed: {
    signaturePadOptions() {
      return {
        penColor: '#000F55',
        onBegin: this.onBegin,
        onEnd: this.onEnd,
        backgroundColor: this.disabled ? 'rgba(239,239,239,0.3)' : 'rgba(0,0,0,0)',
      };
    },
    // iOS() {
    //   return [
    //     'iPad Simulator',
    //     'iPhone Simulator',
    //     'iPod Simulator',
    //     'iPad',
    //     'iPhone',
    //     'iPod',
    //   ].includes(navigator.platform)
    //       // iPad on iOS 13 detection
    //       || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    // },
  },

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
    disabled: function () {
      this.updateLock();
    },
  },

  created: function () {
    this.internalValue = this.value;
    this.mouseAttached = matchMedia('(pointer:fine)').matches || false;
    this.signed = !isEmpty(this.value);
    this.setInterval(this.updateLock, 500);
  },

  mounted: function () {
    this.mounted = true;
    this.updateLock();
  },

  methods: {
    isEmpty,
    trimCanvas(data, offset = 0, threshold = 0) {
      return Promise.resolve(data);
      // if (this.iOS) return Promise.resolve(data);
      //
      // const canvas = this.$el.querySelector('.trimImage');
      // const trimCanvasCtx = canvas.getContext('2d');
      // trimCanvasCtx.save();
      // trimCanvasCtx.setTransform(1, 0, 0, 1, 0, 0);
      // trimCanvasCtx.clearRect(0, 0, trimCanvasCtx.width, trimCanvasCtx.height);
      // trimCanvasCtx.restore();
      // canvas.height = 180;
      // canvas.width = 1200;
      //
      // const image = new Image();
      // image.onload = function () {
      //   trimCanvasCtx.drawImage(image, 0, 0);
      //   trimCanvasCtx.save();
      // };
      // image.src = data;
      //
      // // eslint-disable-next-line no-new
      // return new Promise(((resolve) => {
      //   setTimeout(() => {
      //     // SOURCE: https://stackoverflow.com/questions/11796554/automatically-crop-html5-canvas-to-contents
      //
      //     const ctx = canvas.getContext('2d');
      //     const w = canvas.width; const h = canvas.height;
      //     const imageData = ctx.getImageData(0, 0, w, h);
      //     const tlCorner = { x: w + 1, y: h + 1 };
      //     const brCorner = { x: -1, y: -1 };
      //
      //     for (let y = 0; y < h; y++) {
      //       for (let x = 0; x < w; x++) {
      //         if (imageData.data[((y * w + x) * 4) + 3] > threshold) {
      //           tlCorner.x = Math.min(x, tlCorner.x);
      //           tlCorner.y = Math.min(y, tlCorner.y);
      //           brCorner.x = Math.max(x, brCorner.x);
      //           brCorner.y = Math.max(y, brCorner.y);
      //         }
      //       }
      //     }
      //
      //     const cut = ctx.getImageData(Math.max(0, tlCorner.x - offset), Math.max(tlCorner.y - offset, 0), brCorner.x - tlCorner.x + offset, brCorner.y - tlCorner.y + offset);
      //
      //     canvas.width = brCorner.x - tlCorner.x + offset * 2;
      //     canvas.height = brCorner.y - tlCorner.y + offset * 2;
      //
      //     ctx.putImageData(cut, 0, 0);
      //
      //     // return { width: canvas.width, height: canvas.height, x: tlCorner.x, y: tlCorner.y };
      //     resolve(canvas.toDataURL());
      //   }, 100);
      // }));
    },
    saveSignature() {
      if (isNil(this.$refs.signaturePad)) {
        if (!this.signed || this.isEmpty(this.value)) {
          this.internalValue = null;
          return;
        } else {
          return;
        }
      }

      // eslint-disable-next-line no-shadow
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      const dateCanvas = this.$el.querySelector('.dateImage');
      const parentHeight = dateCanvas.parentElement.offsetWidth;
      const signatureOffset = 750;
      dateCanvas.width = parentHeight + signatureOffset;
      const dateCanvasCtx = dateCanvas.getContext('2d');
      dateCanvasCtx.save();
      dateCanvasCtx.setTransform(1, 0, 0, 1, 0, 0);
      dateCanvasCtx.clearRect(0, 0, dateCanvas.width, dateCanvas.height);
      dateCanvasCtx.restore();

      if (isEmpty) {
        this.internalValue = null;
      } else {
        dateCanvasCtx.font = '48px sans-serif';
        dateCanvasCtx.textAlign = 'left';

        const date = `${this.formatDate(fecha.format(new Date(), 'YYYYMMDD'), 'YYYYMMDD')},`;
        dateCanvasCtx.fillText(date, 0, 140);
        const actualTextWidth = dateCanvasCtx.measureText(date).width + 30;

        const image = new Image();
        image.onload = function () {
          dateCanvasCtx.drawImage(image, actualTextWidth, 0, parentHeight, 180);
          dateCanvasCtx.save();
        };

        this.trimCanvas(data, 10).then((res) => {
          image.src = res;
          setTimeout(() => {
            this.internalValue = dateCanvas.toDataURL();
          }, 100);
        });

        // image.src = data;
      }
    },
    onBegin() {
    },
    onEnd() {
      this.saveSignature();
    },
    onClear() {
      this.signed = false;
      if (this.$refs.signaturePad) this.$refs.signaturePad.clearSignature();
      this.$nextTick(this.saveSignature);
    },
    onUndo() {
      this.signed = false;
      if (this.$refs.signaturePad) this.$refs.signaturePad.undoSignature();
      this.$nextTick(this.saveSignature);
    },
    updateLock() {
      const signaturePad = this.$refs.signaturePad;
      if (isNil(signaturePad)) return;

      if (this.disabled) {
        signaturePad.lockSignaturePad();
      } else if (signaturePad.isEmpty()) {
        signaturePad.openSignaturePad();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
