<template>
  <div class="mb-24">
    <label
      class="input-label text-14 text-dark font-medium mb-8 block"
      :class="[ {'invalid': invalid }]"
      :for="name"
    >
      {{ label }} <span class="text-gray">{{ optional ? '(optional)' : '' }}</span>
    </label>
    <!-- :key is required, because placeholder is not interactive and otherwise placeholder stays in there after signature -->
    <flat-pickr
      ref="date"
      :key="`${disabled}`"
      v-model="internalValue"
      :config="flatPickrConfig"
      class="form-control"
      :placeholder="disabled ? '' : placeholder"
      :disabled="disabled"
      name="date"
      @focus="onFocus"
      @blur="onBlur"
    />
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import InputMixin from '../../../mixins/InputMixin';
import ErrorMessage from '../ErrorMessage';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/light.css';
import { German } from 'flatpickr/dist/l10n/de';
import { english } from 'flatpickr/dist/l10n/default';
import fecha from 'fecha';
import yearDropdownPlugin from '../../../plugins/yearDropdownPlugin';

export default {
  components: { ErrorMessage, flatPickr },
  mixins: [InputMixin],
  props: {
    value: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    locale: String,
    minDate: {
      type: String,
      default: '1900-01-01',
    },
    maxDate: {
      type: String,
      default: () => new Date().toISOString().substring(0, 10),
    },
  },

  data: function () {
    return {
      internalValue: null,
    };
  },

  computed: {
    flatPickrConfig() {
      return {
        altInput: true,
        altFormat: this.locale === 'de' ? 'd.m.Y' : 'd-m-Y',
        allowInput: false,
        dateFormat: 'Ymd',
        minDate: new Date(this.minDate),
        maxDate: new Date(this.maxDate),
        disableMobile: this.disabled,
        altInputClass: `input focus:border-primary active:border-primary appearance-none focus:outline-none ${this.class || ''}`,
        formatDate: this.formatDate,
        locale: this.flatPickrLocale,
        // eslint-disable-next-line new-cap
        plugins: [
          // eslint-disable-next-line new-cap
          new yearDropdownPlugin({
            yearStart: Math.max(new Date().getFullYear() - new Date(this.minDate).getFullYear(), 0),
            yearEnd: Math.max(new Date(this.maxDate).getFullYear() - new Date().getFullYear(), 0),
            invalid: this.invalid,
          }),
        ],
      };
    },
    flatPickrLocale() {
      switch (this.locale.toLowerCase().substring(0, 2)) {
        case 'de':
          return German;
        default:
          return english;
      }
    },
  },

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
    },
    invalid: function (newVal) {
      try {
        const date = this.$refs.date;
        if (date && date.fp) {
          const input = date.fp.mobileInput;
          if (input) {
            if (newVal) {
              input.classList.add('invalid');
            } else {
              input.classList.remove('invalid');
            }
          }
        }
      } catch (e) {
        this.$rollbar.error('Failed to set mobile date input validation status', e);
      }
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
  },

  created: function () {
    this.internalValue = this.value;
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    onFocus($event) {
    },
    onBlur($event) {
      this.$emit('blur', $event);
    },
    formatDate(date, requestedFormat, i18n) {
      switch (requestedFormat) {
        case 'Ymd':
          return fecha.format(date, 'YYYYMMDD');
        default:
          break;
      }

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      return new Intl.DateTimeFormat(this.locale, format).format(date);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
.flatpickr-current-month .numInputWrapper {
  display: none;
}
.flatpickr-current-month span.cur-month {
  margin-right: 10px;
  width: 85px;
  font-size: 0.75em;
  top: 0px;
  padding-top: 10px;
  padding-right: 2px;
  position: absolute;
  left: 0px;
  font-weight: 600;
  text-align: right;
}
.flatpickr-current-month {
  width: 100px;
}
.flatpickr-year-select {
  z-index: 100;
  position: absolute;
  top: 8px;
  right: 50px;
  width: auto;

}
.flatpickr-year-select select{
  appearance: menulist;
  display: block;
  background: #eceef1;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: #5a6171;
  cursor: pointer;
  font-size: 135%;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

</style>
