<template>
  <div>
    <div class="contact">
      <div class="description mb-32 select-none">
        <h1 class="h1 mb-32">
          {{ $t('kiosk.headline') }}
        </h1>
        <div class="body text-gray">
          <p>{{ $t('kiosk.waitingForRequests') }}</p>
        </div>
        <pwa-install
          v-if="!isEmpty(manifestUrl)"
          manual-apple="false"
          manual-chrome="false"
          disable-install-description="true"
          :manifest-url="manifestUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNil } from 'lodash/lang';
import ButtonSquare from '@peda/hm-public-vue-component-library/src/components/ButtonSquare/ButtonSquare';
import ButtonBig from '@peda/hm-public-vue-component-library/src/components/ButtonBig/ButtonBig';
import InputSignature from '@/components/inputs/InputSignature';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import KioskMixin from '@/mixins/KioskMixin';
import '@khmyznikov/pwa-install';
import TimerMixin from '@peda/hm-public-vue-component-library/src/mixins/TimerMixin';
import baseUrl from '@/lib/urls';

export default {
  name: 'KioskHotelView',

  components: {
    InputText,
    InputSignature,
    ButtonBig,
    ButtonSquare,
  },

  mixins: [KioskMixin, TimerMixin],
  props: {
    msg: String,
  },

  data: function () {
    return {
      signature: null,
    };
  },

  head: {
    title: function () {
      try {
        return this.$root.$children[0].$options.head.title();
      } catch (e) {
        return 'Kiosk';
      }
    },
    link: function () {
      let parent = [];
      try {
        parent = this.$root.$children[0].$options.head.link() || [];
      } catch (e) { /* ignore */ }
      if (isEmpty(this.manifestUrl)) return [...parent];
      return [...parent, { id: 'manifest', rel: 'manifest', href: this.manifestUrl }];
    },
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
    manifestUrl: function () {
      if (isEmpty(this.$route.params.encodedHotelId)) return null;
      return `${baseUrl}/ui/guest/portal/v2.0/${this.$route.params.encodedHotelId}/hotel/kiosk.webmanifest`;
    },
  },

  created() {
    const pwaInstallPrompt = this.setInterval(() => {
      const pwaInstall = document.getElementsByTagName('pwa-install')[0];
      if (isNil(pwaInstall)) return;
      if (pwaInstall.isInstallAvailable !== true) return;
      this.clearInterval(pwaInstallPrompt);
      pwaInstall.showDialog();
    }, 500);
  },

  beforeDestroy() {
  },

  methods: {
    isNil,
    isEmpty,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
