import { isEmpty, isNil } from 'lodash/lang';
import fecha from 'fecha';
import { head } from 'lodash/array';
import LocaleMixin from '@/mixins/LocaleMixin';

export default {

  mixins: [LocaleMixin],

  methods: {

    formattedDateRange: function (arrivalDate, departureDate) {
      if (isNil(departureDate) || isNil(arrivalDate)) return null;

      const arrivalDateDate = fecha.parse(arrivalDate, 'YYYYMMDD');
      const departureDateDate = fecha.parse(departureDate, 'YYYYMMDD');

      if (isNil(departureDateDate) || isNil(arrivalDateDate)) return null;

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      const dtf = new Intl.DateTimeFormat(this.locale, format);

      let delimiter = ' - ';

      if (dtf.formatToParts) {
        const parts = dtf.formatToParts(arrivalDateDate);

        delimiter = head(parts.filter((it) => it.type === 'literal' && it.source === 'shared' && it.value.trim() !== ''));
      }

      return `${this.formattedArrivalDate(arrivalDateDate, departureDateDate)}${isEmpty(delimiter) ? ' – ' : delimiter}${this.formattedDepartureDate(arrivalDateDate, departureDateDate)}`;
    },
    formattedArrivalDate: function (arrivalDateDate, departureDateDate) {
      if (isNil(arrivalDateDate)) return null;

      const format = {
        day: 'numeric',
        month: 'long',
      };

      if (isNil(departureDateDate) || arrivalDateDate.getFullYear() !== departureDateDate.getFullYear()) format.year = 'numeric';

      return new Intl.DateTimeFormat(this.locale, format).format(arrivalDateDate);
    },
    formattedDepartureDate: function (arrivalDateDate, departureDateDate) {
      if (isNil(departureDateDate) || isNil(arrivalDateDate)) return null;

      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, format).format(departureDateDate);
    },
  },
};
