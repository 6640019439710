<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white opacity-90 flex flex-col items-center justify-center backdrop-blur-md">
    <span class="mb-12">
      <i class="text-primary fas fa-circle-notch fa-spin fa-5x"></i>
    </span>
    <h2 class="text-center text-dark text-24 font-semibold">
      {{ $t('loading') }}
    </h2>
    <p v-if="msg" class="w-1/3 text-center text-dark text-12">
      {{ msg }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Loading',

  props: ['msg'],

  created() {
    this.$serviceBus.$on('shortcut.esc', this.cancel);
  },

  beforeDestroy() {
    this.$serviceBus.$off('shortcut.esc', this.cancel);
  },

  methods: {
    cancel() {
      this.$serviceBus.$emit('back');
    },
  },

  i18n: {
    messages: {
      de: {
        loading: 'Laden ...',
      },
      en: {
        loading: 'Loading ...',
      },
      'es': {
        'loading': 'Cargando...',
      },
      'fr': {
        'loading': 'Chargement...',
      },
      'it': {
        'loading': 'Caricamento...',
      },
      'nl': {
        'loading': 'Laden...',
      },
      'pl': {
        'loading': 'Ładowanie...',
      },
      'pt': {
        'loading': 'A carregar...',
      },
      'ru': {
        'loading': 'Загрузка...',
      },
    },
  },
};
</script>

<style scoped lang="scss">
  .backdrop-blur {
    &-md {
      backdrop-filter: blur(4px);
    }
  }
</style>
