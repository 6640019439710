<template>
  <div v-if="!isNil(hotel)" class="text-dark">
    <h2 class="h2 mt-32">
      {{ $t('headline') }}
    </h2>
    <div class="body text-gray pt-16 leading-250">
      <mail-icon class="inline-block mr-12" /><a :href="`mailto:${hotel.details.emailReservation}`">{{ hotel.details.emailReservation }}</a><br />
      <phone-icon class="inline-block mr-12" /><a :href="`tel:${hotel.details.reservationPhoneNumber}`">{{ hotel.details.reservationPhoneNumber }}</a>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from 'vue-feather-icons';
import { isNil } from 'lodash/lang';

export default {
  name: 'HotelContact',

  components: {
    MailIcon, PhoneIcon,
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
  },

  methods: {
    isNil,
  },

};
</script>

<style scoped>

</style>
