// define a mixin object
import { isEmpty, isNil } from 'lodash/lang';
import Cookies from 'js-cookie';

export default {

  data() {
    return {
      consent: null,
    };
  },

  crated() {
    this.$serviceBus.$on('cookieconsent.run', this.runCookieconsent);
  },

  mounted() {
    this.runCookieconsent();
  },

  beforeDestroy() {
    this.$serviceBus.$off('cookieconsent.run', this.runCookieconsent);
    window.removeEventListener('cc_preferencesSavePressed', this.updateCookieconsent);
    window.removeEventListener('cc_dialogOkPressed', this.updateCookieconsent);
    window.removeEventListener('cc_userChangedConsent', this.updateCookieconsent);
  },

  watch: {
    '$i18n.locale': function (newLocale) {
      if (!isNil(this.consent)) {
        let hidden = true;
        if (!isNil(this.consent.consentBanner) && !isNil(this.consent.consentBanner.dialog)) {
          hidden = this.consent.consentBanner.dialog.classList.contains('hidden');
          if (!hidden) this.consent.consentBanner.hideDialog();
          this.consent.consentBanner.dialog.remove();
          this.consent.consentBanner.dialog = null;
        }
        this.consent.language = newLocale;
        this.consent.ownerSiteLanguage = newLocale;
        this.consent.i18n.detectUserLanguage();
        this.$nextTick(() => {
          this.showCookieconsent();
          if (hidden) this.consent.consentBanner.hideDialog();
        });
      }
    },
  },

  methods: {
    showCookieconsent() {
      try {
        this.consent.consentBanner.initDialog();
        this.consent.consentBanner.dialog.classList.remove('hidden');
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    runCookieconsent() {
      // eslint-disable-next-line no-undef
      if (isNil(cookieconsent)) {
        this.setTimeout(this.runCookieconsent, 250);
        return;
      }

      // eslint-disable-next-line no-undef
      this.consent = cookieconsent.run({
        'notice_banner_type': 'simple',
        'consent_type': 'express',
        'palette': 'light',
        'language': this.$i18n.locale,
        'page_load_consent_levels': ['strictly-necessary'],
        'notice_banner_reject_button_hide': false,
        'preferences_center_close_button_hide': false,
        'website_name': isEmpty(this.name) ? 'Guest Portal' : this.name,
      });

      window.consent = this.consent;

      this.updateCookieconsent();

      window.removeEventListener('cc_preferencesSavePressed', this.updateCookieconsent);
      window.removeEventListener('cc_dialogOkPressed', this.updateCookieconsent);
      window.removeEventListener('cc_userChangedConsent', this.updateCookieconsent);

      window.addEventListener('cc_preferencesSavePressed', this.updateCookieconsent);
      window.addEventListener('cc_dialogOkPressed', this.updateCookieconsent);
      window.addEventListener('cc_userChangedConsent', this.updateCookieconsent);
    },
    updateCookieconsent() {
      this.setTimeout(() => {
        if (this.consent && this.consent.userConsent && this.consent.userConsent.acceptedLevels && this.consent.userConsent.acceptedLevels.tracking) {
          this.$serviceBus.$emit('cookie.tracking', true);
        } else {
          this.$serviceBus.$emit('cookie.tracking', false);
        }
      }, 250);
    },
  },
};
