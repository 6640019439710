export default {
  hotel: {
    get: ($hotelId) => `/ui/guest/portal/v2.0/${$hotelId}/hotel`,
    privacy: ($hotelId, $locale) => `/wbe/v2.0/${$hotelId}/legal/privacy/${$locale}.html`,
    imprint: ($hotelId, $locale) => `/wbe/v2.0/${$hotelId}/legal/imprint/${$locale}.html`,
  },
  reservationGroup: {
    get: ($hotelId, $reservationGroupIdOrConfirmationCode, $pinCode) => `/ui/guest/portal/v2.0/${$hotelId}/reservationGroup/${$reservationGroupIdOrConfirmationCode}/${$pinCode}`,
    meldeblatt: ($hotelId, $reservationGroupIdOrConfirmationCode, $pinCode) => `/ui/guest/portal/v2.0/${$hotelId}/reservationGroup/${$reservationGroupIdOrConfirmationCode}/${$pinCode}/meldeblatt`,
  },
  paymentRequest: {
    get: ($hotelId, $paymentRequestShortId) => `/ui/guest/portal/v2.0/${$hotelId}/paymentRequest/${$paymentRequestShortId}`,
  },
  meldeblatt: {
    get: ($hotelId, $encodedMeldeblattId, $hmac) => `/ui/guest/portal/v2.0/${$hotelId}/meldeblatt/${$encodedMeldeblattId}/${$hmac}`,
  },
};
