export default {

  watch: {
    '$i18n.locale': function (newLocale) {
      if (window.i18n) {
        window.i18n.locale = newLocale;
      } else {
        window.i18n = {
          locale: newLocale,
        };
      }
    },
  },

  created() {
    if (this.$i18n && this.$i18n.locale) {
      if (!window.i18n || window.i18n.locale !== this.$i18n.locale) {
        if (window.i18n) {
          window.i18n.locale = this.$i18n.locale;
        } else {
          window.i18n = {
            locale: this.$i18n.locale,
          };
        }
      }
    }
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
