/* eslint-disable implicit-arrow-linebreak */
import Vue from 'vue';
import VueRouter from 'vue-router';
import OpenReservationView from '@/components/views/OpenReservationView';
import EmptyNav from '@peda/hm-public-vue-component-library/src/components/EmptyNav';
import ErrorView from '@/components/views/ErrorView';
import ReservationGroupNav from '@/components/nav/ReservationGroupNav';
import ReservationGroupView from '@/components/views/ReservationGroupView';
import PaymentRequestView from '@/components/views/PaymentRequestView';
import PaymentRequestNav from '@/components/nav/PaymentRequestNav';
import KioskConnectionView from '@/components/views/KioskConnectionView';
import KioskView from '@/components/views/KioskView';
import KioskHotelView from '@/components/views/KioskHotelView';
import BasicKioskNav from '@/components/nav/BasicKioskNav';
import MeldeblattView from '@/components/views/MeldeblattView';
import KioskMeldeblattNav from '@/components/nav/KioskMeldeblattNav';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'error',
    components: {
      nav: EmptyNav,
      default: ErrorView,
    },
  },
  {
    path: '/kiosk',
    name: 'kiosk',
    components: {
      nav: EmptyNav,
      default: KioskView,
    },
    meta: {
      kiosk: true,
    },
  },
  {
    path: '/:encodedHotelId/kiosk',
    name: 'kiosk.hotel',
    components: {
      nav: EmptyNav,
      default: KioskConnectionView,
    },
    meta: {
      kiosk: true,
    },
  },
  {
    path: '/:encodedHotelId/kiosk/waiting',
    name: 'kiosk.hotel.ready',
    components: {
      nav: BasicKioskNav,
      default: KioskHotelView,
    },
    meta: {
      kiosk: true,
    },
  },
  {
    path: '/:encodedHotelId/kiosk/meldeblatt/:encodedMeldeblattId/:hmac',
    name: 'kiosk.meldeblatt',
    components: {
      nav: KioskMeldeblattNav,
      default: MeldeblattView,
    },
    meta: {
      kiosk: true,
      wide: true,
    },
  },
  {
    path: '/:encodedHotelId/reservationGroup',
    name: 'home',
    components: {
      nav: ReservationGroupNav,
      default: OpenReservationView,
    },
    meta: {
      back: false,
      wide: false,
    },
    query: {
    },
  },
  {
    // 537684c4cb/reservationGroup/EI7OY1X6/0516
    path: '/:encodedHotelId/reservationGroup/:confirmationNumber/:pinCode',
    name: 'portal.reservationGroup',
    components: {
      nav: ReservationGroupNav,
      default: ReservationGroupView,
    },
    meta: {
      back: false,
      wide: false,
    },
    query: {
    },
  },
  {
    path: '/:encodedHotelId/reservationGroup/:confirmationNumber/:pinCode/meldeblatt',
    name: 'portal.meldeblattForward',
    components: {
      nav: ReservationGroupNav,
      default: MeldeblattView,
    },
    meta: {
      back: false,
      wide: true,
      kiosk: false,
    },
    query: {
    },
  },
  {
    // 537684c4cb/reservationGroup/EI7OY1X6/0516
    path: '/:encodedHotelId/reservationGroup/:confirmationNumber/:pinCode/meldeblatt/:encodedMeldeblattId/:hmac',
    name: 'portal.meldeblatt',
    components: {
      nav: ReservationGroupNav,
      default: MeldeblattView,
    },
    meta: {
      back: false,
      wide: true,
      kiosk: false,
    },
    query: {
    },
  },
  {
    // 537684c4cb/reservationGroup/EI7OY1X6/0516
    path: '/:encodedHotelId/reservationGroup/:confirmationNumber/:pinCode/payment/:paymentRequestShortId',
    name: 'portal.payment',
    components: {
      nav: ReservationGroupNav,
      default: PaymentRequestView,
    },
    meta: {
      back: false,
      wide: false,
      kiosk: false,
    },
    query: {
    },
  },
  {
    // 537684c4cb/EI7OY1X6/0516
    path: '/:encodedHotelId/payment/:paymentRequestShortId',
    name: 'payment',
    components: {
      nav: PaymentRequestNav,
      default: PaymentRequestView,
    },
    meta: {
      back: false,
      wide: false,
    },
    query: {
    },
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if ((isEmpty(to.params) || isEmpty(to.params.encodedHotelId)) && !isEmpty(from) && !isEmpty(from.params) && !isEmpty(from.params.encodedHotelId)) {
//     next({
//       ...to,
//       params: {
//         ...to.params,
//         encodedHotelId: from.params.encodedHotelId,
//       },
//     });
//   } else {
//     next();
//   }
// });

export default router;
