<template>
  <div v-if="!isNil(resGrp)">
    <div class="contact">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('summary.headline') }}
        </h1>
        <div class="body text-gray">
          <div class="grid grid-cols-12 gap-24">
            <input-text
              :value="confirmationNumber"
              name="confirmationNumber"
              :disabled="true"
              :label="$t('summary.confirmationNumber')"
              class="col-span-12"
            ></input-text>
            <template v-if="!isNil(resGrp)">
              <input-text
                v-if="!isNil(resGrp.booker)"
                :value="`${resGrp.booker.displayName}`"
                name="booker"
                :disabled="true"
                :label="$t('summary.booker')"
                class="col-span-12"
              ></input-text>
              <input-text
                :value="`${hotel.details.currency} ${$options.filters.toCurrencyDigits(resGrp.totalPrice.amount + resGrp.totalPrice.excludedTaxAmount)}`"
                name="totalAmount"
                :disabled="true"
                :label="$t('summary.totalAmount')"
                class="col-span-12"
              ></input-text>
              <input-text
                :value="`${resGrp.roomReservations.length}`"
                name="roomCount"
                :disabled="true"
                :label="$t('summary.roomCount')"
                class="col-span-12"
              ></input-text>
              <div class="col-span-12">
                <button v-if="!showDetails" class="text-primary" @click="toggleDetails">
                  <span class="text-14 text-primary font-medium text-right block flex">
                    <chevron-down-icon class="text-primary"></chevron-down-icon>
                    {{ $t('summary.showReservationDetails') }}
                  </span>
                </button>
                <button v-else class="text-primary" @click="toggleDetails">
                  <span class="text-14 text-primary font-medium text-right block flex">
                    <chevron-up-icon class="text-primary"></chevron-up-icon>
                    {{ $t('summary.hideReservationDetails') }}
                  </span>
                </button>
              </div>
              <template v-if="showDetails">
                <template v-for="(res, resIdx) in resGrp.roomReservations">
                  <div class="booking-details border-grayLight border-1 rounded-8 py-24 mb-32 bg-white col-span-12">
                    <div class="heading px-24 border-b border-grayLight pb-16 mb-24">
                      <div class="title flex justify-between mb-8">
                        <span class="font-semibold text-20 text-dark">{{ $t('summary.nthRoom', [resIdx + 1]) }}</span>
                      </div>
                    </div>
                    <div class="arrival-departure px-24 border-b border-grayLight pb-24 mb-24">
                      <div class="title flex justify-between mb-8">
                        <span class="font-semibold text-14 text-dark">{{ $t('summary.dates') }}</span>
                      </div>
                      <div class="details">
                        <div class="mb-4">
                          <span class="text-16 text-dark">{{ formattedDateRange(res.arrivalDate, res.departureDate) }}</span>
                        </div>
                        <div>
                          <span class="text-14 text-gray">{{ $tc('summary.nights', res.rates.reduce((a,b) => a + b.numberOfNights, 0), [res.rates.reduce((a,b) => a + b.numberOfNights, 0)]) }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="guests px-24 border-b border-grayLight pb-24 mb-24">
                      <div class="title flex justify-between mb-8">
                        <span class="font-semibold text-14 text-dark">{{ $t('summary.primaryGuest') }}</span>
                      </div>
                      <div class="details">
                        <div class="mb-4">
                          <span class="text-16 text-dark">
                            {{ res.displayName }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="guests px-24 border-b border-grayLight pb-24 mb-24">
                      <div class="title flex justify-between mb-8">
                        <span class="font-semibold text-14 text-dark">{{ $t('summary.guests') }}</span>
                      </div>
                      <div class="details">
                        <div class="mb-4">
                          <i18n path="summary.roomRequestDescription" tag="span" class="text-16 text-dark">
                            <template #adults>
                              {{ $tc('summary.roomRequestDescription1', res.numAdults, [res.numAdults]) }}
                            </template>
                            <template v-if="res.numChildren > 0" #children>
                              {{ $tc('summary.roomRequestDescription2', res.numChildren, [res.numChildren]) }}
                            </template>
                          </i18n>
                        </div>
                        <div>
                          <span class="text-14 text-gray">
                            <template v-for="(acId, acIdx) in res.childAgeCategories">
                              <template v-for="ac in [ageCategoryMap[acId]]">
                                1 x {{ ac.name[locale] }} <template v-if="ac.ageFrom >= 0">
                                  ({{ ac.ageFrom }} - {{ ac.ageUntil }})
                                </template>
                                <template v-if="(acIdx + 1) < res.numChildren">
                                  ,
                                </template>
                              </template>
                            </template>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="room px-24 border-b border-grayLight pb-24 mb-24">
                      <div class="title flex justify-between mb-8">
                        <span class="font-semibold text-14 text-dark">
                          {{ $t('summary.room', [resIdx + 1]) }}
                        </span>
                      </div>
                      <template v-for="roomType in [roomTypeMap[res.roomTypeId]]">
                        <template v-for="ratePlan in [ratePlanMap[res.ratePlanId]]">
                          <div class="details flex relative">
                            <div class="image rounded-3 w-64 h-64 mr-16 overflow-hidden">
                              <template v-if="isNil(roomType) || isEmpty(roomType.thumbImages)">
                                <img class="object-cover w-full h-full" :src="brokenImageUrl">
                              </template>
                              <template v-else>
                                <img class="object-cover w-full h-full" :src="roomType.thumbImages[0]">
                              </template>
                            </div>
                            <div>
                              <div v-if="!isNil(roomType)" class="mb-4">
                                <span class="text-16 text-dark">{{ roomType.name[locale] }}</span>

                                <info-icon
                                  v-if="!isEmpty(roomType.description[locale])"
                                  v-tooltip="{ content: roomType.description[locale] }"
                                  size="24"
                                  class="text-grayBlue cursor-help inline-block ml-6 absolute right-0"
                                ></info-icon>
                              </div>
                              <div v-if="!isNil(ratePlan)">
                                <span class="text-gray text-14">{{ ratePlan.name[locale] }}</span>

                                <info-icon
                                  v-if="!isEmpty(ratePlan.description[locale])"
                                  v-tooltip="{ content: ratePlan.description[locale] }"
                                  size="24"
                                  class="text-grayBlue cursor-help inline-block ml-6 absolute right-0"
                                ></info-icon>
                              </div>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                    <div v-if="!isEmpty(res.shoppingCartItems)" class="addons px-24 border-b border-grayLight pb-24 mt-16 text-16 text-dark">
                      <div class="flex justify-between mb-8">
                        <span class="font-semibold text-14 text-dark">{{ $t('summary.selectedAdditionalServices') }}</span>
                      </div>
                      <ul class="addons__list">
                        <template v-for="shoppingCartItem in res.shoppingCartItems">
                          <li>
                            <span>{{ shoppingCartItem.itemCount }} x {{ isNil(inHouseServiceMap[shoppingCartItem.inHouseServiceId]) ? shoppingCartItem.inHouseServiceId : inHouseServiceMap[shoppingCartItem.inHouseServiceId].name[locale] }}</span>
                            <div v-if="!isNil(inHouseServiceMap[shoppingCartItem.inHouseServiceId])" class="info">
                              <button>
                                <info-icon
                                  v-if="!isEmpty(inHouseServiceMap[shoppingCartItem.inHouseServiceId].description[locale])"
                                  v-tooltip="{ content: inHouseServiceMap[shoppingCartItem.inHouseServiceId].description[locale] }"
                                  size="24"
                                  class="text-grayBlue cursor-help"
                                ></info-icon>
                              </button>
                            </div>
                          </li>
                        </template>
                      </ul>
                    </div>
                    <div class="costs px-24 pb-24 mt-16 text-16 text-dark">
                      <div class="flex justify-between mb-8">
                        <span class="font-semibold text-14 text-dark">{{ $t('summary.costs') }}</span>
                      </div>
                      <div>
                        <table class="w-full table">
                          <tr>
                            <td class="pb-16">
                              <div>
                                <span class="">{{ isNil(ratePlanMap[res.ratePlanId]) ? '' : ratePlanMap[res.ratePlanId].name[locale] }}</span>
                              </div>
                              <div>
                                <span class="text-gray text-14">
                                  {{ hotel.details.currency }} {{ res.unitPriceSummary.amount / res.rates.length | toCurrencyDigits }} {{ $t('summary.perNight') }}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span class="text-16 text-dark text-right block whitespace-no-wrap">
                                {{ hotel.details.currency }}&nbsp;{{ res.unitPriceSummary.amount | toCurrencyDigits }}
                              </span>
                            </td>
                            <td class="w-24">
                              <info-icon
                                v-tooltip="{ content: nightRates(res) }"
                                size="24"
                                class="text-grayBlue cursor-help inline-block ml-6 relative -top-1"
                              ></info-icon>
                            </td>
                          </tr>
                          <template v-for="tax in res.unitPriceSummary.taxDetails">
                            <template v-if="tax.isInclusive === false">
                              <tr :key="`ratePlan-${tax.taxId}`">
                                <td class="pb-16">
                                  <div><span class="">{{ taxMap[tax.taxId].name[locale] }}</span></div>
                                  <template v-if="taxMap[tax.taxId].percentage === false">
                                    <span class="text-gray text-14">
                                      {{ hotel.details.currency }}&nbsp;{{ taxMap[tax.taxId].amount | toCurrency }}
                                      <template v-if="taxMap[tax.taxId].rateMode === 'PerPerson'">
                                        {{ $t('summary.perPerson') }}
                                      </template>
                                      <template v-if="taxMap[tax.taxId].rateFrequency === 'PerNight'">
                                        {{ $t('summary.perNight') }}
                                      </template>
                                      <template v-if="taxMap[tax.taxId].rateFrequency === 'PerStay'">
                                        {{ $t('summary.perStay') }}
                                      </template>
                                    </span>
                                  </template>
                                </td>
                                <td>
                                  <span class="text-16 text-dark text-right block whitespace-no-wrap">
                                    {{ hotel.details.currency }}&nbsp;{{ tax.amount | toCurrencyDigits }}
                                  </span>
                                </td>
                                <td class="w-24" />
                              </tr>
                            </template>
                          </template>
                          <template v-for="shoppingCartItem in res.shoppingCartItems">
                            <tr :key="shoppingCartItem.inHouseServiceId">
                              <td class="pb-16">
                                <div>
                                  <span class=""><template v-if="shoppingCartItem.itemCount > 1">{{ shoppingCartItem.itemCount }} x </template>{{ inHouseServiceMap[shoppingCartItem.inHouseServiceId].name[locale] }}</span>
                                </div>
                                <span class="text-gray text-14">
                                  {{ hotel.details.currency }}&nbsp;{{ shoppingCartItem.itemPrice | toCurrency }}
                                  <template
                                    v-if="inHouseServiceMap[shoppingCartItem.inHouseServiceId].rateMode === 'PerPerson'"
                                  >
                                    {{ $t('summary.perPerson') }}
                                  </template>
                                  <template
                                    v-if="inHouseServiceMap[shoppingCartItem.inHouseServiceId].rateFrequency === 'PerNight'"
                                  >
                                    {{ $t('summary.perNight') }}
                                  </template>
                                <!--                                  <template v-if="inHouseServiceMap[shoppingCartItem.inHouseServiceId].rateFrequency === 'PerStay'">-->
                                <!--                                    {{ $t('summary.perStay') }}-->
                                <!--                                  </template>-->
                                </span>
                              </td>
                              <td>
                                <span class="text-16 text-dark text-right block whitespace-no-wrap">
                                  {{ hotel.details.currency }}&nbsp;{{ shoppingCartItem.unitPriceSummary.amount | toCurrencyDigits }}
                                </span>
                              </td>
                              <td class="w-24" />
                            </tr>
                            <template v-for="tax in shoppingCartItem.unitPriceSummary.taxDetails">
                              <template v-if="tax.isInclusive === false">
                                <tr :key="`shoppingCart-${shoppingCartItem.inHouseServiceId}-${tax.taxId}`">
                                  <td class="pb-16">
                                    <div><span class="">{{ taxMap[tax.taxId].name[locale] }}</span></div>
                                  </td>
                                  <td>
                                    <span class="text-16 text-dark text-right block whitespace-no-wrap">
                                      {{ hotel.details.currency }}&nbsp;{{ tax.amount | toCurrencyDigits }}
                                    </span>
                                  </td>
                                  <td class="w-24" />
                                </tr>
                              </template>
                            </template>
                          </template>

                          <tr>
                            <td class="pb-16 pt-24 border-t-2 border-dark">
                              <div><span class="text-gray text-16 pr-16">{{ $t('summary.totalAmount') }}</span></div>
                            </td>
                            <td class="pb-16 pt-24 border-t-2 border-dark">
                              <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">{{ hotel.details.currency }}&nbsp;{{ res.unitPriceSummary.amount + res.unitPriceSummary.excludedTaxAmount + res.shoppingCartSummary.amount + res.shoppingCartSummary.excludedTaxAmount | toCurrencyDigits }}</span>
                            </td>
                            <td class="border-t-2 border-dark w-24"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <!--              <div v-if="!isEmpty(resGrp.paymentRequests)" class="payment-requests border-grayLight border-1 rounded-8 py-24 mb-32 bg-white col-span-12">-->
              <!--                <div class="heading px-24 border-b border-grayLight pb-16 mb-24">-->
              <!--                  <div class="title flex justify-between mb-8">-->
              <!--                    <span class="font-semibold text-20 text-dark">{{ $t('summary.paymentRequests') }}</span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <template v-for="(paymentRequest, paymentRequestIdx) in resGrp.paymentRequests">-->
              <!--                  <div-->
              <!--                    class="payment-request px-24 pb-24"-->
              <!--                    :class="[{ 'cursor-pointer': ['Initial'].includes(paymentRequest.status) }, {'border-b border-grayLight mb-24': (paymentRequestIdx + 1) < resGrp.paymentRequests.length}]"-->
              <!--                    @click="onClickPaymentRequest(paymentRequest)"-->
              <!--                  >-->
              <!--                    <div class="title flex justify-between mb-8">-->
              <!--                      <span v-if="paymentRequest.status === 'Complete'" class="font-semibold text-14 text-dark">{{ $t('summary.payment', [`${paymentRequest.currency || hotel.details.currency} ${$options.filters.toCurrencyDigits(paymentRequest.amount)}`]) }}</span>-->
              <!--                      <span v-else class="font-semibold text-14 text-dark">{{ $t('summary.paymentRequest', [`${paymentRequest.currency || hotel.details.currency} ${$options.filters.toCurrencyDigits(paymentRequest.amount)}`]) }}</span>-->
              <!--                      <button v-if="['Initial'].includes(paymentRequest.status)">-->
              <!--                        <span class="text-14 text-primary font-medium text-right block">Jetzt bezahlen!</span>-->
              <!--                      </button>-->
              <!--                    </div>-->
              <!--                    <div class="details">-->
              <!--                      <div class="mb-4">-->
              <!--                        <div v-if="paymentRequest.status === 'Complete'">-->
              <!--                          <span class="text-16 text-success font-semibold"><check-icon class="inline-block mr-6" /> {{ $t(`PaymentRequestStatus.${paymentRequest.status}`) }}</span>-->
              <!--                        </div>-->
              <!--                        <div v-else-if="paymentRequest.status === 'InProgress'">-->
              <!--                          <span class="text-16 text-warn font-semibold">{{ $t(`PaymentRequestStatus.${paymentRequest.status}`) }}</span>-->
              <!--                        </div>-->
              <!--                        <div v-else-if="paymentRequest.status === 'Initial'">-->
              <!--                          <span class="text-16 text-blue font-semibold">{{ $t(`PaymentRequestStatus.${paymentRequest.status}`) }}</span>-->
              <!--                        </div>-->
              <!--                        <div v-else-if="paymentRequest.status === 'Failed'">-->
              <!--                          <span class="text-16 text-error font-semibold"><x-icon class="inline-block mr-6" /> $t(`PaymentRequestStatus.${paymentRequest.status}`) }}</span>-->
              <!--                        </div>-->
              <!--                        <div v-else-if="paymentRequest.status === 'Cancelled'">-->
              <!--                          <span class="text-16 text-error font-semibold"><x-icon class="inline-block mr-6" /> {{ $t(`PaymentRequestStatus.${paymentRequest.status}`) }}</span>-->
              <!--                        </div>-->
              <!--                        <div v-else>-->
              <!--                          <span class="text-16 text-dark font-semibold">{{ $t(`PaymentRequestStatus.Unknown`) }}</span>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                      <div v-if="paymentRequest.expired">-->
              <!--                        <span class="text-14 text-gray">{{ $t('summary.paymentRequestExpired', [formatDateTime(paymentRequest.expireAt, 'isoDateTime')]) }}</span>-->
              <!--                      </div>-->
              <!--                      <div v-else-if="['Initial', 'InProgress'].includes(paymentRequest.status)">-->
              <!--                        <span class="text-14 text-gray">{{ $t('summary.paymentRequestExpires', [formatDateTime(paymentRequest.expireAt, 'isoDateTime')]) }}</span>-->
              <!--                      </div>-->
              <!--                      <div v-else>-->
              <!--                        <span class="text-14 text-gray">{{ $t('summary.paymentRequestCreated', [formatDateTime(paymentRequest.createdAt, 'isoDateTime')]) }}</span>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--              </div>-->

              <!--              <pre class="col-span-12" v-html="resGrp.paymentRequests" />-->
            </template>
          </div>
          <hotel-contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isFunction, isNil } from 'lodash/lang';
import scrollIntoView from 'scroll-into-view-if-needed';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import InputText from '@peda/hm-public-vue-component-library/src/components/InputElements/InputText/InputText';
import FormatDateRangeMixin from '@/mixins/FormatDateRangeMixin';
import { InfoIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon, XIcon } from 'vue-feather-icons';
import { keyBy } from 'lodash/collection';
import { join } from 'lodash/array';
import FilterMixin from '@peda/hm-public-vue-component-library/src/mixins/FilterMixin';
import HotelContact from '@/components/util/HotelContact';

export default {
  name: 'ReservationGroupView',

  components: {
    HotelContact,
    InputText,
    InfoIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CheckIcon,
    XIcon,
  },

  mixins: [FormatDateRangeMixin, FilterMixin],
  props: {
    msg: String,
  },

  data: function () {
    return {
      brokenImageUrl: 'https://hotelsoftware.twic.pics/img/assets/bell.jpg?twic=v1/focus=auto/cover-max=800/quality-max=90/output=preview',
      showDetails: false,
    };
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
    taxMap() {
      return keyBy(this.hotel.taxes, 'id');
    },
    ageCategoryMap() {
      return keyBy(this.hotel.ageCategories, 'id');
    },
    roomTypeMap() {
      return keyBy(this.hotel.roomTypes, 'id');
    },
    ratePlanMap() {
      return keyBy(this.hotel.ratePlans, 'id');
    },
    inHouseServiceMap() {
      return keyBy(this.hotel.inHouseServices, 'id');
    },
    resGrp() {
      return this.$store.getters['portal.reservationGroup'];
    },
    confirmationNumber() {
      return this.$store.getters['portal.confirmationNumber'];
    },
    pinCode() {
      return this.$store.getters['portal.pinCode'];
    },
  },

  methods: {
    isNil,
    isEmpty,
    validate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.setTimeout(() => {
          const invalidElement = this.$el.querySelector('label.invalid');
          if (isFunction(invalidElement.scrollIntoView)) {
            scrollIntoView(
              invalidElement,
              {
                scrollMode: 'if-needed',
                block: 'nearest',
                inline: 'nearest',
                behavior: 'smooth',
              },
            );
          }
        }, 250);
      }
    },
    isInvalid(fieldName) {
      return !isNil(this.$v[fieldName]) && this.$v[fieldName].$dirty && this.$v[fieldName].$invalid;
    },
    nightRates(offer) {
      return `<table>
${join(offer.rates.map((it, idx) => `<tr><td class="pr-16">${this.$t('summary.nthNight', [idx + 1])}</td><td>${this.hotel.details.currency}&nbsp;${this.$options.filters.toCurrencyDigits(it.amount)}</td></tr>`), '')}
</table>`;
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    onClickPaymentRequest(paymentRequest) {
      if (['Initial'].includes(paymentRequest.status)) {
        window.open(paymentRequest.pspUrl, '_blank');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.addons {
  &__list {
    li {
      @apply mb-8 flex gap-x-8;

      &:last-of-type {
        @apply mb-0;
      }
    }
  }
}

</style>
