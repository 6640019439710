<template>
  <div>
    <h1 class="h1 mb-32">
      Error
    </h1>
    <p class="mb-8">
      <!-- de -->
      Diese Seite ist keinem Betrieb zugeordnet, vermutlich sind Sie über einen fehlerhaften Link auf diese Seite gelangt.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- en -->
      This page is not assigned to a company, and you may have reached this page via an incorrect link.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- nl -->
      Deze pagina is niet toegewezen aan een bedrijf en je hebt deze pagina mogelijk via een onjuiste link bereikt.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- es -->
      Esta página no está asignada a una empresa y es posible que haya llegado a esta página mediante un enlace incorrecto.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- it -->
      Questa pagina non è assegnata a un'azienda e potresti aver raggiunto questa pagina tramite un collegamento errato.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- fr -->
      Cette page n'est pas attribuée à une entreprise et vous avez peut-être atteint cette page via un lien incorrect.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- ru -->
      Эта страница не назначена компании, и вы, возможно, попали на эту страницу по неверной ссылке.
    </p>
    <hr />
    <p class="mt-8 mb-8">
      <!-- zh -->
      此页面没有分配给公司，您可能是通过不正确的链接到达此页面。
    </p>
    <hr />
    <p class="mt-8 mb-8 text-rtl">
      <!-- he -->
      דף זה אינו מוקצה לחברה, וייתכן שהגעת לדף זה באמצעות קישור שגוי.
    </p>
    <hr />
    <p class="mt-8 mb-8 text-rtl">
      <!-- ae -->
      لم يتم تعيين هذه الصفحة لشركة، وربما وصلت إلى هذه الصفحة عبر رابط غير صحيح.
    </p>
    <!--    <button-floating v-if="!isEmpty(lastHotelId)" @click="navigateToHotel">-->
    <!--      Zurück / Back-->
    <!--    </button-floating>-->
  </div>
</template>

<script>
import { isEmpty } from 'lodash/lang';
import ButtonFloating from '@peda/hm-public-vue-component-library/src/components/ButtonFloating/ButtonFloating';

export default {
  name: 'ErrorView',
  components: {
    'button-floating': ButtonFloating,
  },

  data: function () {
    return {
      lastHotelId: null,
    };
  },

  created: function () {
    try {
      this.lastHotelId = localStorage.getItem('lastHotelId');
    } catch (_) { /* ignore */ }
  },

  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
    navigateToHotel() {
      this.$router.push({ name: 'home', params: { encodedHotelId: this.lastHotelId } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/styles/import';

hr {
  border-color: $grayBlue!important;
}

.text-rtl {
  text-align: right;
  direction: rtl;
}
</style>
